import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, IconButton, Typography } from "@material-ui/core";
import b64 from "base-64";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ElectionAccessModal = ({ company, dispatch }) => {
  const [token, setToken] = useState(null);
  const [tokenManager, setTokenManager] = useState(null);

  const generateToken = useCallback((type) => {
    return (
      window.location.origin +
      "/election/" +
      b64.encode(type + "+" + company.companyId)
    );
  }, [company]);

  const copyToken = (url) => {
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    setToken(generateToken("employee"));
    setTokenManager(generateToken("manager"));
  }, [generateToken]);

  return (
    <>
      <Box>
        <Typography variant="subtitle1">
          Eleição:
          <IconButton onClick={() => copyToken(token)}>
            <ContentCopyIcon />
          </IconButton>
        </Typography>
        <Typography variant="body">{token}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          Gestor:
          <IconButton onClick={() => copyToken(tokenManager)}>
            <ContentCopyIcon />
          </IconButton>
        </Typography>
        <Typography variant="body">{tokenManager}</Typography>
      </Box>
    </>
  );
};

const mapsStateToProps = (state) => ({
  company: state.company.company,
});

export default connect(mapsStateToProps)(ElectionAccessModal);
