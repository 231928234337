import React, { useState } from "react";

import { Box, Button, Typography, Divider } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import { useFormik } from "formik";
import * as yup from "yup";

import TextInput from "../../../../UI/TextInput/TextInput";
import SelectBox from "../../../../UI/SelectBox";
import CheckBox from "../../../../UI/Checkbox";
import { getUTCDate, getUTCDateObj } from "components/UI/utils";

const validationSchema = yup.object({
  name: yup.string().required("Requerido"),
  registration: yup.string().required("Requerido"),
  username: yup.string().required("Requerido"),
  password: yup.string().required("Requerido"),
  areaId: yup.string().required("Requerido"),
});

const EmployeesForm = ({
  employee = {},
  onNewEmployee,
  onUpdatedEmployee,
  areaList,
  onCancel,
  uniqueArea,
  managerAccess,
}) => {
  const [isCandidate, setIsCandidate] = useState(
    employee ? employee.isCandidate : false
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: employee?.name || "",
      surname: employee?.surname || "",
      email: employee?.email || "",
      registration: employee?.registration || "",
      department: employee?.department,
      isCandidate: employee?.isCandidate || false,
      username: employee?.username || "",
      password: employee?.password || "",
      areaId: employee?.areaId || (uniqueArea ? "unique" : ""),
      lastAreaId: employee?.lastAreaId || "",
    },
    validationSchema: validationSchema,
    onSubmit: (data, { resetForm }) => {
      resetForm({});

      if (!data.lastAreaId || data.lastAreaId === "") {
        data.lastAreaId = data.areaId;
      }

      data.isCandidate = isCandidate;

      if (data.isCandidate && !data.subscribeDate) {
        const subscribeDateArr = getUTCDate(new Date(), true, true).split(" ");
        const subscribeDate = subscribeDateArr[0];
        const subscribeTime = subscribeDateArr[1];

        data.subscribeDate = subscribeDate;
        data.subscribeTime = subscribeTime;
        data.subscriptionNumber = getUTCDateObj(
          subscribeDate,
          subscribeTime
        ).unix();
      } else {
        data.subscribeDate = null;
        data.subscribeTime = null;
        data.subscriptionNumber = null;
      }

      if (employee && employee.subscribeNumber) {
        onUpdatedEmployee({
          ...employee,
          ...data,
        });

        return;
      }

      onNewEmployee({
        ...data,
        subscribeNumber: uuidv4(),
        votedIn: null,
        photoUrl: null,
        isSubscribeApproved: false,
      });
    },
  });

  return (
    <Box>
      <Box my={1}>
        <Typography variant="h2">
          {employee && employee?.subscribeNumber
            ? "Editar Funcionario"
            : "Adicionar Funcionario"}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {!managerAccess ? (
          <>
            <Box my={1}>
              <TextInput
                fieldId="name"
                label="Nome"
                formik={formik}
              ></TextInput>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="surname"
                label="Apelido"
                formik={formik}
              ></TextInput>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="department"
                label="Departamento"
                formik={formik}
              ></TextInput>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="email"
                label="E-mail"
                formik={formik}
              ></TextInput>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="registration"
                label="Matrícula"
                formik={formik}
              ></TextInput>
            </Box>
          </>
        ) : null}
        <Box my={1}>
          <CheckBox
            fieldId="isCandidate"
            label="É um candidato"
            checked={isCandidate}
            onChecked={(vl) => {
              setIsCandidate(vl);
            }}
          />
        </Box>
        {!uniqueArea && !managerAccess ? (
          <Box my={1}>
            <SelectBox
              fieldId="areaId"
              formik={formik}
              label="Area"
              fullWidth
              options={areaList.map((area) => ({
                value: area.areaId,
                text: area.areaName,
              }))}
              disabled={employee?.votedIn ? true : false}
            />
            {employee?.votedIn ? (
              <Typography>
                Não é possível alterar a area pois o usuário já voltou
              </Typography>
            ) : null}
          </Box>
        ) : null}

        <Box my={3}>
          <Divider />
        </Box>

        {!managerAccess ? (
          <>
            <Box my={1}>
              <Typography>Dados de Acesso</Typography>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="username"
                label="Usuário"
                formik={formik}
              ></TextInput>
            </Box>

            <Box my={1}>
              <TextInput
                fieldId="password"
                label="Senha"
                formik={formik}
              ></TextInput>
            </Box>
          </>
        ) : null}

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box m={1}>
            <Button type="submit" variant="contained" color="primary">
              {employee && employee?.subscribeNumber
                ? "Atualizar"
                : "Adicionar"}
            </Button>
          </Box>
          <Box m={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onCancel()}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EmployeesForm;
