import * as notificationActions from "../actions/notificationActions";

const initialState = {
  messages: [],
  isConnected: false,
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case notificationActions.SET_IS_CONNECTED:
      state.isConnected = true;
      return state;
    case notificationActions.NEW_MESSAGE:
      state.messages.push(action.payload);
      return state;

    default:
      return state;
  }
}
