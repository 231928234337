import React from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    fontSize: "1.5rem",
  },
}));

const ApplicationBar = ({ addClicked = null, logoutClicked = null }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h1" className={classes.title} noWrap>
          MQV Pro
        </Typography>

        {addClicked ? (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={addClicked}
          >
            <AddIcon />
          </IconButton>
        ) : null}

        {logoutClicked ? (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={logoutClicked}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default ApplicationBar;
