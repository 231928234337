import React, { useState, useEffect } from "react";

import { Typography, Box, IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/RemoveCircle";

import EmployeesFilter from "./EmployeesFilter";
import TableWithPagination from "components/UI/Table";
import EMPLOYEE_COLUMNS from "./employeeColumns";

const EmployeeList = ({
  areas,
  onEmployeeEditRequest,
  onEmployeeRemoved,
  managerAccess,
}) => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState(null);

  let filter = null;
  let notFound = null;

  useEffect(() => {
    const areasEmployees = [];

    areas.forEach((area) => {
      area.employees.forEach((employee) => {
        areasEmployees.push({
          ...employee,
          areaName: area.areaName,
        });
      });
    });

    setEmployees(areasEmployees);
  }, [areas]);

  filter = (
    <Box mb={2} key="item-list">
      <EmployeesFilter
        employeesList={employees}
        areasList={areas}
        onResult={(list) => {
          setFilteredEmployees(list);
        }}
      />
    </Box>
  );

  if ((filteredEmployees || employees).length === 0) {
    notFound = (
      <Box
        key="employee-not-found"
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={3}
      >
        <Typography variant="body1">
          Não foram encontratos funcionarios
        </Typography>
      </Box>
    );
  }

  const list = filteredEmployees || employees;

  const TableActions = ({ item }) => {
    const employee = item;

    return (
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => onEmployeeEditRequest(employee)}
          >
            <EditIcon />
          </IconButton>
        </Box>

        {!managerAccess ? (
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              style={{ color: "red" }}
              aria-label="menu"
              onClick={() => onEmployeeRemoved(employee)}
            >
              <RemoveIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    );
  };

  function employeeSort(e1, e2) {
    if (e1.name > e2.name) {
      return 1;
    } else if (e1.name < e2.name) {
      return -1;
    }
    
    return 0;
  }

  return (
    <>
      {filter}
      {notFound ? (
        notFound
      ) : (
        <TableWithPagination
          columns={EMPLOYEE_COLUMNS}
          rows={list}
          sort={employeeSort}
          Action={TableActions}
          ActionAlign="center"
          ActionLabel="Ações"
        />
      )}
    </>
  );
};

export default EmployeeList;
