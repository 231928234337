import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import * as companyActions from "store/actions/companyActions";
import UploadInput from "components/UI/UploadInput/UploadInput";
import ErrorMessage from "components/UI/ErrorMessage/ErrorMessage";

const CandidatePhoto = ({ employee, onCancel, dispatch, company }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [photoSrc, setPhotoSrc] = useState("/images/no-photo.jpg");

  useEffect(() => {
    if (employee.photoUrl) setPhotoSrc(employee.photoUrl);
  }, [employee, setPhotoSrc]);

  const uploadPhotoHandler = () => {
    if (!file) {
      return setError("Selecione uma nova foto");
    }
    setLoading(true);
    dispatch(companyActions.uploadCandidatePhoto(company, employee, file))
      .then(() => {
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setError("Ocorreu um erro ao inserir a imagem do candidato");
      });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" my={3}>
        <Typography variant="body1">Foto do candidato</Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={3}
        style={{
          borderRadius: "100%",
          width: "130px",
          border: "1px solid #ccc",
          height: "120px",
          overflow: "hidden",
          margin: "0 auto",
        }}
      >
        <img
          style={{ width: "100%" }}
          src={file ? URL.createObjectURL(file) : photoSrc}
          alt="Foto do candidato"
        />
      </Box>

      {loading ? (
        <Box
          py={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          key="company-loading"
        >
          <CircularProgress />
        </Box>
      ) : null}

      <Box m={1}>
        <UploadInput
          label="Escolher Foto"
          id="photo-uploader"
          onChange={setFile}
          color="default"
          hideUploadInfo
        />
      </Box>

      {error ? <ErrorMessage severity="error">{error}</ErrorMessage> : null}

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={uploadPhotoHandler}
          >
            Salvar
          </Button>
        </Box>
        <Box m={1}>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Voltar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  isLoading: state.company.isLoading,
  errorMessage: state.company.errorMessage,
});

export default connect(mapStateToProps)(CandidatePhoto);
