export const REPORT_TYPES = {
  VOTANTS: "VOTANTS",
  NO_VOTANTS: "NO_VOTANTS",
  ELECTION_RESULTS: "ELECTION_RESULTS",
  CANDIDATES: "CANDIDATES",
  EMPLOYEES: "EMPLOYEES",
};

export const REPORT_PROPERTIES = {
  VOTANTS: "votants",
  NO_VOTANTS: "noVotants",
  ELECTION_RESULTS: "candidates",
  CANDIDATES: "candidates",
  EMPLOYEES: "employees",
};

export const REPORT_ENUM = {
  NO_VOTANTS: "Lista de Funcionários que Não Votaram",
  ELECTION_RESULTS: "Resultado da Eleição",
  CANDIDATES: "Lista de Candidatos",
  EMPLOYEES: "Lista de Funcionarios",
  VOTANTS: "Lista de Funcionários que Votaram",
};
