import React, { useCallback, useEffect, useState } from "react";

import { Box, Typography } from "@material-ui/core";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { connect } from "react-redux";

import * as uiActions from "../../../../store/actions/uiActions";
import * as companyActions from "../../../../store/actions/companyActions";

import ElectionStatusActions from "./ElectionStatusActions/ElectionStatusActions";

import ElectionAccessModal from "../ElectionAccessModal/ElectionAccessModal";
import PercentBox from "../../../UI/PercentBox/PercentBox";
import { ELECTION_STATES_NAMES } from "enums/electionStates";

const ElectionStatus = ({ election, dispatch, company, managerAccess }) => {
  const [results, setResults] = useState({});

  const onSetToken = () => {
    dispatch(
      uiActions.showMqvModal(
        "Token de Acesso",
        <ElectionAccessModal maxWidth="800" />
      )
    );
  };

  const getElectionResult = (area) => {
    let votes = 0;
    let candidates = [];
    let totalEmployees = area.employees.length;

    area.employees.forEach((employee) => {
      if (employee && employee.votedIn) {
        votes++;
      }

      if (employee.isCandidate && employee.isSubscribeApproved) {
        candidates.push(employee);
      }
    });

    const percent = (votes * 100) / totalEmployees;
    const percentTerco = ((totalEmployees / 3) * 100) / totalEmployees;

    return {
      totalCandidates: candidates.length,
      totalVotes: votes,
      electionPercentVotes: !isNaN(percent) ? percent : 0,
      percentTerco: percentTerco,
    };
  };

  const onClickChangeElectionStatusButton = (status) => {
    dispatch(companyActions.changeElectionStatus(company.companyId, status));
  };

  const electionResults = useCallback(() => {
    let totalCandidates = 0;
    let totalEmployees = 0;
    let totalVotes = 0;

    const cells = election.areas.map((area, index) => {
      const electionResult = getElectionResult(area);

      const areaSuficient =
        (electionResult.electionPercentVotes >= electionResult.percentTerco);

      totalCandidates += electionResult.totalCandidates;
      totalEmployees += area.employees.length;
      totalVotes += electionResult.totalVotes;

      return (
        <TableRow
          key={area.areaName}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell align="left" component="th" scope="row">
            {election.isElectionPerArea ? area.areaName : "Percentual"}
          </TableCell>
          <TableCell align="center">{area.employees.length}</TableCell>
          <TableCell align="center">{electionResult.totalCandidates}</TableCell>
          <TableCell align="center">{electionResult.totalVotes}</TableCell>
          <TableCell align="right">
            <PercentBox
              progress={electionResult.electionPercentVotes}
              suficient={areaSuficient}
            />
          </TableCell>
        </TableRow>
      );
    });

    const percent = (totalVotes * 100) / totalEmployees;
    const percentTerco = ((totalEmployees / 3) * 100) / totalEmployees;

    let suficient = percent >= percentTerco;

    setResults({
      areas: cells,
      totalCandidates: totalCandidates,
      totalEmployees: totalEmployees,
      totalVotes: totalVotes,
      percent: !isNaN(percent) ? percent : 0,
      suficient: suficient,
    });
  }, [election]);

  useEffect(() => {
    electionResults();
  }, [company, electionResults]);

  if (election.areas.length === 0) {
    return (
      <Box my={1} key="election-not-areas">
        <Typography variant="body1">
          Não há áreas para iniciar uma eleição
        </Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box mb={3}>
        <Typography variant="h6">
          Status Eleição: {ELECTION_STATES_NAMES(company.election.status)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box flexGrow={1}>
          {election.areas.length > 0 ? (
            <ElectionStatusActions
              onClickAccessTokenButton={() => onSetToken()}
              onClickChangeElectionStatusButton={(status) =>
                onClickChangeElectionStatusButton(status)
              }
              status={election.status}
              electionStates={company.electionStates}
              managerAccess={managerAccess}
            ></ElectionStatusActions>
          ) : null}
        </Box>
        <Box flexGrow={3} pr={3}>
          <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Area</TableCell>
                <TableCell align="center">Funcionarios</TableCell>
                <TableCell align="center">Candidatos</TableCell>
                <TableCell align="center">Votos</TableCell>
                <TableCell align="right" style={{ minWidth: "200px" }}>
                  Porcentagem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {company.election.isElectionPerArea ? results.areas : null}
              <TableRow
                key="total"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Total
                </TableCell>
                <TableCell align="center">{results.totalEmployees}</TableCell>
                <TableCell align="center">{results.totalCandidates}</TableCell>
                <TableCell align="center">{results.totalVotes}</TableCell>
                <TableCell align="right">
                  <PercentBox
                    progress={results.percent}
                    suficient={results.suficient}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  messages: state.notification.messages,
  managerAccess: state.company.managerAccess,
});

export default connect(mapStateToProps)(ElectionStatus);
