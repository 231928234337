import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ThemeProvider } from "@material-ui/core";
import { AppContext } from "./libs/contextLib";
import Routes from "./Routes";
import "./App.css";
import theme from "./theme";
import { connect } from "react-redux";
import * as notificationActions from "store/actions/notificationActions";

const socketEndpoint = process.env.REACT_APP_AWS_WEBSOCKET;

function App({ dispatch, notification }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();
  const socket = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (socket.current) {
      return;
    }

    socket.current = new WebSocket(socketEndpoint);

    socket.current.onopen = () => {
      console.log("connected to websocket!");
      dispatch(notificationActions.setIsConnected(true));
      socket.current.send(JSON.stringify({ action: "newUserLogged", type: "USER_LOGGED_IN", data: {}}));
    };

    socket.current.onclose = () => {
      socket.current = null;
      console.log("disconnected to websocket!");
      window.setTimeout(() => {
        console.log("Trying to connect again to websocket...");
        connectWebSocket();
      }, 2000);
    };

    socket.current.onmessage = (ev) => {
      dispatch(notificationActions.newMessage(ev.data));
    }
  }, [dispatch]);

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      } catch (e) {
        if (
          history.location.pathname !== "/login" &&
          history.location.pathname !== "/new-password" &&
          history.location.pathname.indexOf("/election") === -1
        ) {
          history.push("/login");
        }
      }

      setIsAuthenticating(false);
    }

    connectWebSocket();
    onLoad();
  }, [isAuthenticating, isAuthenticated, history, connectWebSocket]);

  return (
    !isAuthenticating && (
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, socket: socket.current }}>
          <Routes />
        </AppContext.Provider>
      </ThemeProvider>
    )
  );
}

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps)(App);
