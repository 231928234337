import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Divider, Box, Typography } from "@material-ui/core";

import PageTitle from "../../UI/PageTitle/PageTitle";
import SearchElections from "./SearchElections/SearchElections";
import ElectionCards from "./ElectionCards/ElectionCards";
import { ELECTION_FOLLOW_STATES, ELECTION_STATES_NAMES } from "enums/electionStates";
import { useHistory } from "react-router-dom";

import * as companyActions from "../../../store/actions/companyActions";

const Elections = ({ companies, dispatch }) => {
  const [foundElections, setFoundElections] = useState([]);
  const [elections, setElections] = useState([]);
  const history = useHistory()

  useEffect(() => {
    if (companies) {
      setElections(
        companies
          .filter((company) => ELECTION_FOLLOW_STATES.indexOf(company.election.status) !== -1)
          .map((company) => {
            return {
              logoUrl: company.logoUrl,
              company: company,
              companyName: company.name,
              name: company.election.name,
              surName: company.surname,
              companyId: company.companyId,
              status: company.election.status,
              statusName: ELECTION_STATES_NAMES(company.election.status)
            };
          })
      );
    }
  }, [companies]);

  useEffect(() => {
    setFoundElections(elections);
  }, [elections]);

  const foundElectionsHandler = useCallback((elections) => {
    setFoundElections(elections);
  }, []);

  let electionsItems = (
    <Typography variant="body2">Nenhuma eleição encontrada</Typography>
  );

  let electionContainer = (
    <Typography variant="body2">Nenhuma eleição cadastrada</Typography>
  );
  
  const redirectTo = (path) => {
    history.push(path);
  }

  const setCompanyHandler = (company) => {
    dispatch(companyActions.setCompany(company, (currentCompany) => {
      redirectTo("/company/" + currentCompany.companyId);
    }));
  }

  if (foundElections.length > 0) {
    electionsItems = (
      <Box my={3}>
        <ElectionCards elections={foundElections} setCompany={setCompanyHandler}></ElectionCards>
      </Box>
    );
  }

  if (elections.length > 0) {
    electionContainer = (
      <>
        <Box my={3}>
          <SearchElections
            elections={elections}
            foundElections={foundElectionsHandler}
          ></SearchElections>
        </Box>
        <Divider />
        {electionsItems}
      </>
    );
  }

  return (
    <React.Fragment>
      <PageTitle title="Eleições" bolder />
      <Box my={3}>{electionContainer}</Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({ companies: state.company.list });

export default connect(mapStateToProps)(Elections);
