import React from "react";

import { Box } from "@material-ui/core";
import CheckBox from "../../../../UI/Checkbox";

const Options = ({
  isAlphabetic,
  setAlphabetic,
  isShowSurname,
  setShowSurname,
}) => {
  return (
    <Box
      py={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection="row"
    >
      <Box>
        <CheckBox
          fieldId="chb-setAlphabetic"
          label="Candidatos em ordem alfabetica"
          checked={isAlphabetic}
          onChecked={(vl) => setAlphabetic(vl)}
        />
      </Box>
      <Box>
        <CheckBox
          fieldId="chb-setShowSurname"
          label="Exibir Apelido"
          checked={isShowSurname}
          onChecked={(vl) => setShowSurname(vl)}
        />
      </Box>
    </Box>
  );
};

export default Options;
