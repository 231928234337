import React from "react";

import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

const CheckBox = ({ label, checked = false, fieldId, onChecked }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={fieldId}
            onChange={(ev) => onChecked(ev.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={label}
      ></FormControlLabel>
    </FormGroup>
  );
};

export default CheckBox;
