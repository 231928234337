import React from "react";
import { withRouter } from "react-router-dom";

import { Box, Typography, CircularProgress } from "@material-ui/core";

import PageTitle from "../../UI/PageTitle/PageTitle";
import CompanyCards from "./CompanyCards/CompanyCards";
import SearchCompany from "./SearchCompany/SearchCompany";

//import api from "../../../services/api";
import InternalErrorMessage from "../../UI/InternalErrorMessage/InternalErrorMessage";

import { connect } from "react-redux";

import * as companyActions from "../../../store/actions/companyActions";
import store from "../../../store";

const mapStateToProps = (state) => ({
  companies: state.company.list,
  isLoading: state.company.isLoading,
  hasErrors: state.company.hasErrors,
});
class Companies extends React.Component {
  unsubscribe = null;
  state = {
    foundCompanies: null,
  };

  foundCompaniesHandler = (companies) => {
    this.setState({ foundCompanies: companies });
  };

  componentDidMount() {
    this.props.dispatch(companyActions.fetchCompanies());

    this.unsubscribe = store.subscribe((param) => {
      const storeState = store.getState();

      if (storeState.company.list) {
        this.foundCompaniesHandler(storeState.company.list);
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  onDeleteHandler(company) {
    this.props.dispatch(companyActions.deleteCompany(company));
  }

  redirectTo = (path) => {
    this.props.history.push(path);
  };

  onAdminCompanyHandler(company) {
    this.props.dispatch(
      companyActions.setCompany(company, (currentCompany) => {
        this.redirectTo("/company/" + currentCompany.companyId);
      })
    );
  }

  render() {
    let rendered = [];

    if (!this.state.foundCompanies) {
      rendered.push(
        <Box
          py={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          key="companyLoading"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (this.state.foundCompanies) {
      if (this.props.companies.length > 0) {
        rendered = [
          <Box my={3} key="search-company">
            <SearchCompany
              companies={this.props.companies}
              foundCompanies={this.foundCompaniesHandler}
            />
          </Box>,
        ];
      }

      let companies = (
        <Box my={3} key="error-message">
          <Typography variant="body1" key="error-message">
            Não há empresas cadastradas ou não foram encontradas
          </Typography>
        </Box>
      );

      if (this.state.foundCompanies && this.state.foundCompanies.length > 0) {
        companies = (
          <CompanyCards
            key="company-list"
            companies={this.state.foundCompanies}
            clicked={(company) => this.onAdminCompanyHandler(company)}
            deleteClicked={(company) => this.onDeleteHandler(company)}
          />
        );
      }

      rendered.push(companies);
    }

    if (this.state.error) {
      rendered = (
        <Box py={3}>
          <InternalErrorMessage err={this.state.error} />
        </Box>
      );
    }

    return (
      <section className="companies">
        <PageTitle title="Empresas" />

        {rendered}
      </section>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Companies));
