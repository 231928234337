import React from "react";

import { Link } from "react-router-dom";

import { Box, Button, TextField, makeStyles, colors } from "@material-ui/core";
import ErrorMessage from "../../UI/ErrorMessage/ErrorMessage";

import * as yup from "yup";
import { useFormik } from "formik";

const styles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginBox: {
    background: colors.grey[50],
  },
  title: {
    fontWeight: "bold",
  },
}));

const validationSchema = yup.object({
  username: yup.string().required("Requerido"),
  password: yup.string().required("Requerido"),
});

export default function Login(props) {
  const classes = styles();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      props.onSubmitHandler(data.username, data.password);
    },
  });

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        boxShadow={3}
        p={3}
        borderRadius={8}
        maxWidth="350px"
        className={classes.loginBox}
      >
        <Box textAlign="center" mt={7}>
          <img src="/logo-mqv-pro.png" alt="logo" />
        </Box>

        <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
          <Box my={5}>
            <TextField
              id="username"
              type="text"
              label="Usuário"
              variant="outlined"
              fullWidth
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              margin="normal"
            />
            <TextField
              id="password"
              type="password"
              label="Senha"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>

          {props.errorMessage ? (
            <ErrorMessage severity="error">{props.errorMessage}</ErrorMessage>
          ) : null}

          <Box my={5}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Entrar
            </Button>
            <Box mt={2} textAlign={"center"}>
              <Link to={"/forgot-password"}>Recuperar a senha</Link>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
