import { TextField, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { sanitizeTerm } from "../../../../utils/string";

const searchCompany = (props) => {
  const onChangeHandler = (ev) => {
    const term = sanitizeTerm(ev.target.value);

    if (!term) {
      props.foundCompanies(props.companies);
      return;
    }

    const foundCompanies = props.companies.filter((company) => {
      const companyName = company.name.toLowerCase();
      const companySurname = company.surname.toLowerCase();

      return (
        companyName.indexOf(term) !== -1 || companySurname.indexOf(term) !== -1
      );
    });

    props.foundCompanies(foundCompanies);
  };
  return (
    <TextField
      variant="outlined"
      placeholder="Buscar"
      fullWidth={true}
      onChange={onChangeHandler}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
};

export default searchCompany;
