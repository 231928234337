import { Box, Button, Typography } from "@material-ui/core";

const Dialog = ({ message, onAccept, onDecline }) => {
  return (
    <>
      <Box my={3} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1">{message}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <Button variant="contained" color="primary" onClick={() => onAccept()}>
            Sim
          </Button>
        </Box>

        <Box ml={2}>
          <Button
            ml={2}
            variant="contained"
            color="secondary"
            onClick={() => onDecline()}
          >
            Não
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Dialog;
