import React from "react";

import { makeStyles } from "@material-ui/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DvrIcon from "@material-ui/icons/Dvr";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import { useAppContext } from "../../../libs/contextLib";

import { useHistory } from "react-router-dom";

import Drawer from "../../UI/Drawer/Drawer";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
  },
  listItem: {
    marginBottom: theme.spacing(3),
  },
  list: {
    flexGrow: 1,
  },
}));

const SideMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();

  const proccessLogout = async () => {
    await Auth.signOut();

    userHasAuthenticated(false);
    RedirectTo("/login");
  };

  const RedirectTo = (path) => {
    history.push(path);
  };

  return (
    <Drawer>
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          button
          onClick={() => RedirectTo("/")}
        >
          <ListItemIcon>
            <DvrIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
      </List>

      <Divider />

      <List className={classes.list} style={{ maxHeight: "60px" }}>
        <ListItem
          className={classes.listItem}
          button
          onClick={() => proccessLogout()}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideMenu;
