import React, { useState, useEffect } from "react";

import { Typography, Box, IconButton, Button } from "@material-ui/core";

import RemoveIcon from "@material-ui/icons/RemoveCircle";
import AccountBox from "@material-ui/icons/AccountBox";
import AssignmentIcon from "@material-ui/icons//Assignment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import EmployeesFilter from "./EmployeesFilter";
import { EnrollmentVoucher } from "../../ElectionCandidates/EnrollmentVoucher";

const CandidateList = ({
  areas,
  onEmployeeUploadPhotoRequest,
  onEmployeeAproveRequest,
  onPreview,
  company,
  managerAccess,
}) => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState(null);

  const actions = (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Box pl={1}>
        <Button variant="contained" onClick={() => onPreview()}>
          Prévia
        </Button>
      </Box>
    </Box>
  );
  let filter = null;
  let notFound = null;

  useEffect(() => {
    const areasEmployees = [];

    areas.forEach((area) => {
      area.employees.forEach((employee) => {
        if (employee.isCandidate) {
          areasEmployees.push({
            ...employee,
            areaName: area.areaName,
          });
        }
      });
    });

    areasEmployees.sort((ea, eb) => {
      const isAfter = ea.subscriptionNumber > eb.subscriptionNumber;

      if (isAfter) {
        return 0;
      } else if (!isAfter) {
        return -1;
      } else {
        return 0;
      }
    });

    setEmployees(areasEmployees);
    setFilteredEmployees(areasEmployees);
  }, [areas]);

  filter = (
    <Box mb={2} key="item-list">
      <EmployeesFilter
        employeesList={employees}
        areasList={areas}
        onResult={(list) => {
          setFilteredEmployees(list);
        }}
      />
    </Box>
  );

  if ((filteredEmployees || employees).length === 0) {
    notFound = (
      <Box
        key="employee-not-found"
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={3}
      >
        <Typography variant="body1">
          Não foram encontrados candidatos
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {actions}
      {filter}
      {notFound
        ? notFound
        : (filteredEmployees || employees).map((employee, index) => {
            return (
              <Box
                key={"employee-list-item-" + employee.subscribeNumber}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="caption">
                    <b>Número Inscrição: </b>
                    {employee.subscriptionNumber}
                    <br />
                    <b>Registro: </b>
                    {employee.registration},
                    <br />
                    <b>Nome: </b>
                    {employee.name}
                    <br />
                    <b>Area: </b>
                    {employee.areaName}
                  </Typography>
                </Box>

                <Box>
                  {!managerAccess ? (
                    <IconButton
                      edge="start"
                      color="primary"
                      aria-label="menu"
                      onClick={() => onEmployeeUploadPhotoRequest(employee)}
                    >
                      <AccountBox />
                    </IconButton>
                  ) : null}

                  <EnrollmentVoucher
                    reportConfig={{
                      mqvProLogo: window.location.origin + "/logo-mqv-pro.png",
                      imageUrl: company.logoUrl,
                      electionName: company.election.name,
                      companyName: company.name,
                      companyCNPJ: company.cnpj,
                      subscribeNumber: employee.subscribeNumber,
                      employeeName: employee.name,
                      employeeRegister: employee.registration,
                      subscribeDate: employee.subscribeDate,
                      subscribeTime: employee.subscribeTime,
                    }}
                    Component={({ handlerDownload }) => (
                      <IconButton
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        onClick={() => handlerDownload()}
                      >
                        <AssignmentIcon />
                      </IconButton>
                    )}
                  />

                  <IconButton
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    onClick={() => onEmployeeAproveRequest(employee)}
                  >
                    {employee.isSubscribeApproved ? (
                      <RemoveIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                  </IconButton>
                </Box>
              </Box>
            );
          })}
    </>
  );
};

export default CandidateList;
