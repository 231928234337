import React from "react";

import {
	Modal,
	makeStyles,
	Box,
	IconButton,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		width: "100%",
	},
	head: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
    paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(3),
    zIndex: 2,
    backgroundColor: theme.palette.common.white
	},
	body: {
		overflow: "auto",
		maxHeight: "80vh",
    zIndex: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(7)
	},
}));

const AppModal = ({ isOpen, closed, children, title, maxWidth, zIndex }) => {
	const classes = useStyles();
	return (
		<Modal open={isOpen}>
			<div className={classes.root} style={{ maxWidth: maxWidth + "px" || "inherit", zIndex: zIndex || "inherit" }}>
				<Box className={classes.head}>
					<Typography variant="h5">{title}</Typography>
					<IconButton
						color="secondary"
						variant="contained"
						aria-label="Fechar"
						title="Fechar"
						onClick={closed}
					>
						<CloseIcon color="action" />
					</IconButton>
				</Box>
				<Box className={classes.body}>{children}</Box>
			</div>
		</Modal>
	);
};

export default AppModal;
