import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import NewPasswordBox from "../components/NewPasswordBox/NewPasswordBox";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";

export default function NewPassword() {
  const history = useHistory();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [isAuthenticated, history])

  const onSubmitHandler = async (oldPassword, password) => {
    const user = isAuthenticated;
    
    Auth.completeNewPassword(user, password, {}).then((rst) => {
      userHasAuthenticated(rst);

      history.push("/");
    })
    .catch((ex) => {
      setError("Ocorreu um erro tente novamente");
    })
  };
  return <NewPasswordBox onSubmitHandler={onSubmitHandler} errorMessage={error}/>;
}
