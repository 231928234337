import { Box, Button, colors, withStyles } from "@material-ui/core";
import React from "react";

export const voteTypes = {
  TYPE_NULL: "nulo",
  TYPE_WHITE: "branco",
};


const WhiteButton = withStyles({
  root: {
    backgroundColor: colors.common.white,
    color: colors.common.black,
    "&:hover": {
      backgroundColor: colors.common.white,
    },
  },
})(Button);

const OtherVotes = ({ onOtherVote }) => {
  return (
    <Box display={"flex"} justifyContent="center">
      <Box width={"200px"} display="flex" justifyContent="space-evenly">
        <Button
          color="default"
          variant="contained"
          onClick={() => onOtherVote(voteTypes.TYPE_NULL)}
        >
          Nulo
        </Button>
        <WhiteButton
          color="secondary"
          variant="contained"
          onClick={() => onOtherVote(voteTypes.TYPE_WHITE)}
        >
          Branco
        </WhiteButton>
      </Box>
    </Box>
  );
};

export default OtherVotes;
