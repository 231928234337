import React, { useCallback, useEffect, useState } from "react";

import { TextField, InputAdornment, Select, Box } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import classes from "./SearchElections.module.css";
import { sanitizeTerm } from "../../../../utils/string";
import {
  ELECTION_FOLLOW_STATES,
  ELECTION_STATES_NAMES,
} from "enums/electionStates";

const SearchElections = ({ elections, foundElections }) => {
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState(-1);

  const onChangeHandler = (ev) => {
    setTerm(sanitizeTerm(ev.target.value.trim()));
  };

  const onSelectChangeHandler = (ev) => {
    setStatus(parseInt(ev.target.value.trim()));
  };

  const filterElections = useCallback(() => {
    const filteredElections = elections.filter((election) => {
      const foundByCompany = searchByTerm("companyName", term, election);
      const foundBySurname = searchByTerm("surName", term, election);
      const foundByElectionName = searchByTerm("name", term, election);
      const founded = foundByCompany || foundBySurname || foundByElectionName;

      const foundByStatus = searchByStatus(election, status);

      if (!term) {
        return foundByStatus;
      }

      return founded && foundByStatus;
    });

    foundElections(filteredElections);
  }, [status, term, elections, foundElections]);

  const searchByStatus = (election, status) => {
    if (status === -1) return true;

    return election.status === status;
  };

  const searchByTerm = (termKey, termValue, obj) => {
    const item = obj[termKey];
    return item ? item.toLowerCase().indexOf(termValue) !== -1 : false;
  };

  const electionStates = ELECTION_FOLLOW_STATES.map((key) => (
    <option value={key} key={key}>{ELECTION_STATES_NAMES(key)}</option>
  ));

  useEffect(() => {
    filterElections();
  }, [filterElections]);

  return (
    <div className={classes.SearchElections}>
      <Box mb={2}>
        <TextField
          variant="outlined"
          placeholder="Empresa, Apelido, Eleição"
          fullWidth={true}
          onChange={onChangeHandler}
          className={classes.InputFields}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>

      <Box>
        <Select
          native
          id="demo-customized-select"
          onChange={onSelectChangeHandler}
          variant="outlined"
          className={classes.InputFields}
          fullWidth
        >
          <option aria-label="None" value="-1">
            Status
          </option>
          <option value="-1">Todos</option>
          {electionStates}
        </Select>
      </Box>
    </div>
  );
};

export default SearchElections;
