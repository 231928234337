import React, { useState } from "react";
import * as yup from "yup";
import {
  TextField,
  Button,
  Box,
  Typography,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import NumberFormat from "react-number-format";
import Checkbox from "@material-ui/core/Checkbox";

import UploadInput from "../../../UI/UploadInput/UploadInput";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";

const validationSchema = yup.object({
  name: yup
    .string("Insira uma razão social")
    .required("Razão social é requerido"),
  surname: yup.string("Insira um Nome").required("Nome é requerido"),
  city: yup.string("Insira o nome da Cidade").required("Cidade é requerida"),
  state: yup.string("Insira o nome do Estado").required("Estado é requerido"),
  cnpj: yup
    .string("Insira o CNPJ")
    .min(17, "CNPJ inválido")
    .required("CNPJ é requerido"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "@media (max-width: 400px)": {
      width: "270px",
    },
  },
  title: {
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(2) + "px 0",
  },
}));

const Form = ({ initialValue = {}, submit, errorMessage, isLoading }) => {
  const [file, setFile] = useState(null);
  const [isElectionPerArea, setIsElectionPerArea] = useState(false);
  const requestApplicationForm = false;

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      companyId: initialValue.companyId || "",
      name: initialValue.name || "",
      surname: initialValue.surname || "",
      cnpj: initialValue.cnpj || "",
      city: initialValue.city || "",
      state: initialValue.state || "",
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      submit({ data, isElectionPerArea, requestApplicationForm }, file);
    },
  });

  return (
    <Box className={classes.formBox}>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Box id="frm-companyData">
          <Typography variant="subtitle1" className={classes.title}>
            Dados da Empresa
          </Typography>
          <Box my={3}>
            <Box className={classes.formControl}>
              <TextField
                fullWidth
                id="surname"
                name="surname"
                label="Nome"
                value={formik.values.surname}
                onChange={formik.handleChange}
                error={formik.touched.surname && Boolean(formik.errors.surname)}
                helperText={formik.touched.surname && formik.errors.surname}
                variant="outlined"
              />
            </Box>

            <Box className={classes.formControl}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Razão Social"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
              />
            </Box>

            <Box className={classes.formControl}>
              <NumberFormat
                format="##.###.###/####-##"
                customInput={TextField}
                fullWidth
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                value={formik.values.cnpj}
                onChange={formik.handleChange}
                error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                helperText={formik.touched.cnpj && formik.errors.cnpj}
                variant="outlined"
              />
            </Box>

            <Box className={classes.formControl}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="Cidade"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                variant="outlined"
              />
            </Box>

            <Box className={classes.formControl}>
              <TextField
                fullWidth
                id="state"
                name="state"
                label="Estado"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                variant="outlined"
              />
            </Box>
          </Box>

          {!initialValue.companyId ? (
            <Box id="frm-electionData">
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="subtitle1" className={classes.title}>
                Dados da Eleição
              </Typography>
              <Box my={3}>
                <Box className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="electionName"
                    name="electionName"
                    label="Nome da Eleição"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.electionName &&
                      Boolean(formik.errors.electionName)
                    }
                    helperText={
                      formik.touched.electionName && formik.errors.electionName
                    }
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    id="electionExtraInfo"
                    name="electionExtraInfo"
                    label="Descrição Cedula Adicional (Opcional)"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.electionExtraInfo &&
                      Boolean(formik.errors.electionExtraInfo)
                    }
                    helperText={
                      formik.touched.electionExtraInfo &&
                      formik.errors.electionExtraInfo
                    }
                    variant="outlined"
                  />
                </Box>
                <Box
                  className={classes.formControl}
                  style={{ paddingLeft: 10 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isElectionPerArea}
                          onChange={(event) =>
                            setIsElectionPerArea(event.target.checked)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Eleição por area"
                    ></FormControlLabel>
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Box className={classes.formControl}>
            <UploadInput
              label={initialValue.companyId ? "Alterar Logo" : "Escolher Logo"}
              onChange={setFile}
              id="company-logo"
            />
          </Box>
        </Box>

        {errorMessage ? (
          <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
        ) : null}

        <Box my={1}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={
              ((!formik.isValid || !formik.dirty) && !initialValue.companyId) ||
              isLoading
            }
          >
            {initialValue.companyId ? "Salvar" : "Criar"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
