import Layout from "../components/Layouts/PrivateLayout/PrivateLayout";
import CompanyDashboard from "../components/CompanyDashboard/CompanyDashboard";

export default function Company() {
  return (
    <Layout>
      <CompanyDashboard />
    </Layout>
  );
}
