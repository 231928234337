import React from "react";

import { TextField, InputAdornment, Box } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { sanitizeTerm } from "../../../utils/string";
import SelectBox from "../SelectBox";

const Filter = ({ promise = false, fields = [], list = [], doFilter }) => {
  const onChangeHandler = (fieldValue, fieldName) => {
    const term = sanitizeTerm(fieldValue);

    if (!term) {
      doFilter([...list], true);
      return;
    }

    const foundItems = list.filter((item) => {
      const objProperty = item[fieldName];

      let isFound = false;

      if (objProperty && typeof objProperty == "string") {
        isFound = objProperty.toLowerCase().indexOf(term) !== -1;
      }

      if (objProperty && typeof objProperty == "boolean") {
        isFound = objProperty === term;
      }

      return isFound;
    });

    doFilter(foundItems);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {fields.map((field, index) => {
        let render = null;
        if (field.type === "select") {
          render = (
            <Box pr={1} flexGrow={1} key={"selectbox-" + index}>
              <SelectBox
                labelId={field.labelId}
                label={field.label}
                onChange={(ev) =>
                  onChangeHandler(ev.target.value, field.labelId)
                }
                options={field.options}
                fullWidth
                style={field.styles}
              ></SelectBox>
            </Box>
          );
        }

        if (field.type === "text") {
          render = (
            <Box pr={1} flexGrow={1} key={"selectbox-" + index}>
              <TextField
                variant="outlined"
                placeholder={field.label}
                onChange={(e) => onChangeHandler(e.target.value, field.labelId)}
                fullWidth
                style={field.styles}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Box>
          );
        }

        return render;
      })}
    </Box>
  );
};

export default Filter;
