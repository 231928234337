import { Box } from "@material-ui/core";
import React from "react";
import { getUTCDate } from "../../../UI/utils";

import ElectionInfoItem from "./ElectionInfoItem/ElectionInfoItem";
const ElectionInfo = ({
  subscribePeriodDateStart = "--/--/----",
  subscribePeriodDateEnd = "--/--/----",
  subscribePeriodTimeStart = "--:--",
  subscribePeriodTimeEnd = "--:--",
  electionDateStart = "--/--/----",
  electionDateEnd = "--/--/----",
  electionTimeStart = "--:--",
  electionTimeEnd = "--:--",
  responsibleName = "Nenhum",
  totalEmployees = 0,
  TotalEffectives = 0,
  totalCandidates = 0,
  totalAlternates = 0,
}) => {
  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <ElectionInfoItem
          title="Periodo Inscrição"
          items={[
            [
              "Início: ",
              getUTCDate(subscribePeriodDateStart) +
                " " +
                subscribePeriodTimeStart,
            ],
            [
              "Encerramento:  ",
              getUTCDate(subscribePeriodDateEnd) + " " + subscribePeriodTimeEnd,
            ],
          ]}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ElectionInfoItem
          title="Período Eleição"
          items={[
            [
              "Início: ",
              getUTCDate(electionDateStart) + " " + electionTimeStart,
            ],
            [
              "Encerramento:",
              getUTCDate(electionDateEnd) + " " + electionTimeEnd,
            ],
          ]}
        />
      </Box>
    </React.Fragment>
  );
};

export default ElectionInfo;
