import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getUTCDate } from "components/UI/utils";
import {
  EnrollmentVoucher,
} from "components/CompanyDashboard/Election/ElectionCandidates/EnrollmentVoucher";

const Inscription = ({
  employee,
  area,
  dispatch,
  isLoading,
  company,
  hasErrors,
  onSubscribe,
}) => {
  const inscriptionPeriodStart =
    getUTCDate(company.election.subscribePeriodDateStart) +
    " " +
    company.election.subscribePeriodTimeStart;

  const inscriptionPeriodEnd =
    getUTCDate(company.election.subscribePeriodDateEnd) +
    " " +
    company.election.subscribePeriodTimeEnd;

  if (isLoading) {
    return (
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        key="company-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent="center"
      height={"100%"}
    >
      {employee.isCandidate ? (
        <Box maxWidth={"800px"}>
          <Paper>
            <Box p={2}>
              <Typography variant="body1">
                Prezado(a) <strong>{employee.name}</strong>
              </Typography>
              <br />
              <Typography variant="body1">
                Sua inscrição foi realizada com sucesso.
                <br />
                <br />
                No item 5.4.6 da NR5 é permitido apenas uma reeleição, por isso
                se o gestor responsável pelo processo na sua unidade identificar
                que você está se candidatando pelo 3° ano consecutivo, sua
                inscrição será CANCELADA e o comprovante perde a validade.
                <br />
                <br />
                Agradecemos sua participação.
              </Typography>
              <Box py={2}>
                <EnrollmentVoucher
                  reportConfig={{
                    mqvProLogo: window.location.origin + "/logo-mqv-pro.png",
                    imageUrl: company.logoUrl,
                    electionName: company.election.name,
                    companyName: company.name,
                    companyCNPJ: company.cnpj,
                    subscribeNumber: employee.subscribeNumber,
                    employeeName: employee.name,
                    employeeRegister: employee.registration,
                    subscribeDate: employee.subscribeDate,
                    subscribeTime: employee.subscribeTime,
                  }}
                  Component={({ handlerDownload }) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handlerDownload()}
                    >
                      Baixe aqui seu comprovante
                    </Button>
                  )}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Box maxWidth={"800px"}>
          <Paper>
            <Box p={2}>
              <Typography variant="body1">
                Prezado(a) <strong>{employee.name}</strong>
              </Typography>
              <br />
              <Typography variant="body1">
                Sua inscrição poderá ser realizada do dia{" "}
                {inscriptionPeriodStart + " ao dia " + inscriptionPeriodEnd}.
                <br />
                Para se candidatar, clique no botão:
              </Typography>
              <Box py={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubscribe()}
                >
                  Realizar Inscrição
                </Button>
              </Box>
              <Typography variant="body1">
                O botão acima <strong>NÃO</strong> é para participar da Eleição.
                <br />
                Estamos no período de inscrição, por isso só clique em “Realizar
                Inscrição” se você quiser ser CANDIDATO.
              </Typography>
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  isLoading: state.company.isLoading,
  hasErrors: state.company.hasErrors,
  notification: state.company.notification,
});

export default connect(mapStateToProps)(Inscription);
