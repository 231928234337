import React from "react";

export const CANDIDATES = [
  {
    id: "photoUrl",
    label: "Foto",
    width: 60,
    align: "center",
    ignoreInReports: true,
    format: (row, isReport) => {
      if (row.isNotEmployee) {
        return "-";
      }

      const imageUrl = row.photoUrl || "/images/no-photo.jpg";

      if (isReport) {
        return row.photoUrl || "Sem foto";
      }

      return (
        <img
          src={imageUrl}
          style={{ cursor: "pointer", width: 75 }}
          onClick={() => window.open(imageUrl, "_blank")}
          alt="Foto do candidato"
        />
      );
    },
  },
  {
    id: "registration",
    label: "RE | Matrícula",
    minWidth: 170,
    align: "center",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
    align: "center",
  },
  {
    id: "areaName",
    label: "Área de Votação",
    minWidth: 170,
    align: "center",
  },
  {
    id: "department",
    label: "Departamento",
    minWidth: 170,
    align: "center",
  },
];

export const NO_VOTANTS = [
  {
    id: "registration",
    label: "RE | Matrícula",
    minWidth: 170,
    align: "center",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
    align: "center",
  },
  {
    id: "areaName",
    label: "Área de Votação",
    minWidth: 170,
    align: "center",
  },
  {
    id: "department",
    label: "Departamento",
    minWidth: 170,
    align: "center",
  },
  {
    id: "email",
    label: "E-mail",
    minWidth: 170,
    align: "center",
  },
];

export const EMPLOYEES = [
  {
    id: "registration",
    label: "RE | Matrícula",
    minWidth: 170,
    align: "center",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
    align: "center",
  },
  {
    id: "areaName",
    label: "Área de Votação",
    minWidth: 170,
    align: "center",
  },
  {
    id: "department",
    label: "Departamento",
    minWidth: 170,
    align: "center",
  },
  {
    id: "email",
    label: "E-mail",
    minWidth: 170,
    align: "center",
  },
];

export const VOTANTS = [
  {
    id: "registration",
    label: "RE | Matrícula",
    minWidth: 170,
    align: "center",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
    align: "center",
  },
  {
    id: "areaName",
    label: "Área de Votação",
    minWidth: 170,
    align: "center",
  },
  {
    id: "department",
    label: "Departamento",
    minWidth: 170,
    align: "center",
  },
  {
    id: "votedInTime",
    label: "Data/Horário do Voto",
    minWidth: 170,
    align: "center",
  },
  {
    id: "votedInIp",
    label: "IP Origem ",
    minWidth: 170,
    align: "center",
  },
  {
    id: "subscribeNumber",
    label: "Protocolo",
    minWidth: 170,
    align: "center",
  },
];

export const ELECTION_RESULTS = [
  {
    id: "registration",
    label: "RE | Matrícula",
    minWidth: 150,
    align: "center",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
    align: "center",
  },
  {
    id: "areaName",
    label: "Área de Votação",
    minWidth: 170,
    align: "center",
  },
  {
    id: "department",
    label: "Departamento",
    minWidth: 170,
    align: "center",
  },
  {
    id: "votes",
    label: "Votos",
    minWidth: 10,
    align: "center",
  },
  {
    id: "votesPercent",
    label: "%",
    minWidth: 0,
    align: "center",
  },
  {
    id: "isEffective",
    label: "Eleitos",
    minWidth: 0,
    align: "center",
    format: (row) => {
      if (row.isNotEmployee) {
        return "-";
      }

      if (row.isEffective) {
        return "Efetivo";
      }

      if (row.isSuplent) {
        return "Suplente";
      }

      return "Não Eleito";
    },
  },
];
