import React from "react";
import { Box, Typography } from "@material-ui/core";

const ElectionInfoItem = (props) => {
	const rendered = [];

	for (let index in props.items) {
		const item = props.items[index];
		rendered.push(
			<Typography variant="body1" key={item}>{item[0] + ": " + item[1]}</Typography>
		);
	}

	return (
		<Box mb={2}>
			<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
				{props.title}
			</Typography>

			{rendered}
		</Box>
	);
};

export default ElectionInfoItem;
