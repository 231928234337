import {
  Toolbar,
  CssBaseline,
  makeStyles,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AppBar from "../AppBar/AppBar";
import SideMenu from "../SideMenu/SideMenu";
import AddCompanyModal from "../AddCompanyModal/AddCompanyModal";
import MqvModal from "../../UI/MqvModal/MqvModal";
import { getTimeStamp, getUTCDate } from "../../UI/utils";
import { connect } from "react-redux";

import PreviewCandidate from "../../CompanyDashboard/Election/ElectionCandidates/PreviewCandidate";

import * as uiActions from "../../../store/actions/uiActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: "0 " + theme.spacing(3) + "px",
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  notifications: {
    backgroundColor: theme.palette.grey[100],
    width: 280,
    padding: theme.spacing(3),
    boxShadow: "-1px 1px 5px " + theme.palette.grey[400],
    flexGrow: 1,
    maxWidth: 280,
    overflow: "auto",
  },
}));

const PrivateLayout = ({
  children,
  mqvModalOpen,
  mqvModalTitle,
  mqvModalChildren,
  mqvModalSize,
  candidatePreviewModalOpen,
  notifications,
  dispatch,
}) => {
  const classes = useStyles();
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [sortedNotifications, setSortedNotifications] = useState([]);

  const toggleCompanyModal = () => {
    setShowCompanyModal(!showCompanyModal);
  };

  const closeMqvModal = () => {
    dispatch(uiActions.closeMqvModal());
  };

  const closeCandidatePreviewModal = () => {
    dispatch(uiActions.showCandidatePreviewModal(false));
  };

  useEffect(() => {
    console.log("New Notifications !!!", notifications);
    const newNotifications = notifications.sort((n1, n2) => {
      const dateN1 = getTimeStamp(n1.createdAt);
      const dateN2 = getTimeStamp(n2.createdAt);

      if (dateN1 > dateN2) {
        return -1;
      } else if (dateN1 < dateN2) {
        return 1;
      } else {
        return 0;
      }
    });
    setSortedNotifications(newNotifications);
  }, [notifications, setSortedNotifications]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar addClicked={toggleCompanyModal} />
        <SideMenu />

        {showCompanyModal ? (
          <AddCompanyModal closed={() => toggleCompanyModal()} />
        ) : null}

        {mqvModalOpen ? (
          <MqvModal
            title={mqvModalTitle}
            component={mqvModalChildren}
            closed={() => closeMqvModal()}
            maxWidth={mqvModalSize}
          />
        ) : null}

        {candidatePreviewModalOpen ? (
          <MqvModal
            title="Prévia Candidatos"
            component={<PreviewCandidate />}
            closed={() => closeCandidatePreviewModal()}
            maxWidth="900"
            zIndex={2}
          />
        ) : null}

        <Box display="flex" flexGrow={1}>
          <main className={classes.content}>
            <Toolbar />
            {children}
          </main>

          <nav className={classes.notifications}>
            <Toolbar />
            <Typography variant="h6">Notificações</Typography>
            <Box my={2}>
              <Divider />
            </Box>

            {sortedNotifications.map((notification, index) => {
              return (
                <Box
                  my={2}
                  color={notification.color ? notification.color : null}
                  key={index}
                >
                  <Typography variant="subtitle1">
                    <strong>{notification.title}</strong>
                  </Typography>
                  <Box my={2}>
                    <Typography variant="body2">
                      {notification.message}
                    </Typography>

                    {notification.link ? (
                      <a href={notification.link} target="_blank" rel="noreferrer">
                        Acessar
                      </a>
                    ) : null}
                  </Box>
                  <Typography variant="caption">
                    <i>~ {getUTCDate(notification.createdAt, true)}</i>
                  </Typography>
                </Box>
              );
            })}

            {sortedNotifications.length === 0 ? (
              <Box>
                <Typography variant="body1">Nenhuma notificação.</Typography>
              </Box>
            ) : null}
          </nav>
        </Box>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  mqvModalOpen: state.ui.mqvModalOpen,
  mqvModalTitle: state.ui.mqvModalTitle,
  mqvModalChildren: state.ui.mqvModalChildren,
  mqvModalSize: state.ui.mqvModalSize,
  candidatePreviewModalOpen: state.ui.candidatePreviewModalOpen,
  notifications: state.company.notifications,
});

export default connect(mapStateToProps)(PrivateLayout);
