import { Box, Button, colors } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import React from "react";

const ElectionButtonFinished = withStyles({
  root: {
    backgroundColor: colors.grey[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.grey[700],
    },
  },
})(Button);

const ElectionButtonProrrogar = withStyles({
  root: {
    backgroundColor: colors.blueGrey[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.blueGrey[700],
    },
  },
})(Button);

const ElectionButtonVerificate = withStyles({
  root: {
    backgroundColor: colors.purple[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.purple[700],
    },
  },
})(Button);

const ElectionButtonCancel = withStyles({
  root: {
    backgroundColor: colors.red[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.red[700],
    },
  },
})(Button);

const ElectionStatusActions = ({
  onClickAccessTokenButton,
  onClickChangeElectionStatusButton,
  status,
  electionStates,
  managerAccess,
}) => {
  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
        {!managerAccess ? (
          <Box px={1} flexGrow={0}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={onClickAccessTokenButton}
            >
              Token de Acesso
            </Button>
          </Box>
        ) : null}
        {electionStates.FINISHED === status ? (
          <Box px={1} flexGrow={0}>
            <ElectionButtonVerificate
              fullWidth
              color="primary"
              variant="contained"
              onClick={() =>
                onClickChangeElectionStatusButton(electionStates.VERIFICATED)
              }
            >
              Apurar Eleição
            </ElectionButtonVerificate>
          </Box>
        ) : null}

        {!managerAccess &&
        (electionStates.IN_PROGRESS === status ||
          electionStates.PENDING === status) ? (
          <Box px={1} flexGrow={0}>
            <ElectionButtonFinished
              fullWidth
              color="primary"
              variant="contained"
              onClick={() =>
                onClickChangeElectionStatusButton(electionStates.FINISHED)
              }
            >
              Encerrar Eleição
            </ElectionButtonFinished>
          </Box>
        ) : null}

        {!managerAccess && electionStates.PENDING === status ? (
          <Box px={1} flexGrow={0}>
            <ElectionButtonCancel
              fullWidth
              color="default"
              variant="contained"
              onClick={() =>
                onClickChangeElectionStatusButton(electionStates.CANCELED)
              }
            >
              Cancelar Eleição
            </ElectionButtonCancel>
          </Box>
        ) : null}

        {!managerAccess &&
        (electionStates.FINISHED === status ||
          electionStates.PENDING === status) ? (
          <>
            <Box px={1} flexGrow={0}>
              <ElectionButtonProrrogar
                fullWidth
                color="primary"
                variant="contained"
                onClick={() =>
                  onClickChangeElectionStatusButton(electionStates.IN_PROGRESS)
                }
              >
                Retomar Eleição
              </ElectionButtonProrrogar>
            </Box>
          </>
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default ElectionStatusActions;
