import Modal from "../../UI/Modal/Modal";

import CompanyForm from "../../CompanyDashboard/CompanyForm/CompanyForm";
import { Box } from "@material-ui/core";

const AddCompanyModal = (props) => {
	return (
		<Modal isOpen closed={props.closed} title="Nova Empresa" maxWidth="400">
			<Box>
				<CompanyForm></CompanyForm>
			</Box>
		</Modal>
	);
};

export default AddCompanyModal;
