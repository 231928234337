import React from "react";

import {
  Box,
  Typography,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import LinearProgressDanger from "./LinearProgressDanger";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  progress: {
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  progressValue: {
    color: theme.palette.secondary.main,
    minWidth: 5,
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  const danger = props.danger;

  return (
    <Box
      style={{ display: "flex", alignItems: "center" }}
      className={classes.root}
    >
      <Box className={classes.progress}>
        {danger ? (
          <LinearProgressDanger variant="determinate" value={props.value || 0} />
          ) : (
          <LinearProgress variant="determinate" value={props.value || 0} />
        )}
      </Box>
      <Box className={classes.progressValue}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
