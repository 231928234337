import React from "react";

import { Box } from "@material-ui/core";
import Filter from "../../../../../UI/Filter";

const EmployeesFilter = ({
  onResult,
  employeesList = [],
  areasList = [],
  hideNameInput,
}) => {
  const fields = [];

  if (!hideNameInput) {
    fields.push({
      type: "text",
      label: "Nome",
      labelId: "name",
    });
  }

  if (areasList.length > 1) {
    fields.push({
      type: "select",
      label: "Area",
      labelId: "areaId",
      options: areasList.map((area) => {
        return {
          value: area.areaId,
          text: area.areaName,
        };
      }),
    });
  }

  return (
    <Box my={2}>
      <Filter list={employeesList} fields={fields} doFilter={onResult} />
    </Box>
  );
};

export default EmployeesFilter;
