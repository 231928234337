import React, { useState } from "react";

import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";

export default function SelectBox({
  formik = null,
  fieldId,
  label,
  options = [],
  disabled = false,
  onChange = null,
  fullWidth = false,
  selectedValue = null,
  style = null,
  disableSelect = false,
}) {
  const isError =
    formik && formik.touched[fieldId] && Boolean(formik.errors[fieldId]);

  const [defaultValue, setDefaultValue] = useState(
    selectedValue ||
      (formik && formik.values[fieldId] ? formik.values[fieldId] : "")
  );

  const onChangeHandler = (ev) => {
    if (ev.target.value) {
      setDefaultValue(ev.target.value);
    }

    if (onChange) {
      onChange(ev);
      return;
    }

    if (formik) {
      formik.handleChange(ev);
    }
  };

  return (
    <FormControl fullWidth={fullWidth} error={isError} variant="outlined">
      <InputLabel id={"slc-" + fieldId}>{label}</InputLabel>
      <Select
        labelId={"slc-" + fieldId}
        id={fieldId}
        name={fieldId}
        value={defaultValue}
        label={label}
        onChange={onChangeHandler}
        disabled={disabled}
        fullWidth={fullWidth}
        style={style}
      >
        {!disableSelect ? (
          <MenuItem value="">
            <em>Selecione</em>
          </MenuItem>
        ) : null}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
      {isError ? (
        <FormHelperText>
          {formik && formik.touched[fieldId] && formik.errors[fieldId]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
