import React, { useState } from "react";

import { Button, Typography, Box } from "@material-ui/core";

const UploadInput = ({
  id,
  label,
  onChange,
  color = "secondary",
  hideUploadInfo,
}) => {
  const [file, setFile] = useState();
  const chooseLogoHandler = () => {
    const inputFile = document.getElementById(id);
    inputFile.click();
  };

  const onChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    onChange(uploadedFile);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color={color}
        onClick={chooseLogoHandler}
        fullWidth
      >
        {label}
      </Button>
      <input
        type="file"
        style={{ visibility: "hidden", height: "0px", width: "0px" }}
        name="image"
        id={id}
        onChange={onChangeHandler}
      />
      {file && !hideUploadInfo ? (
        <Box py={1}>
          <Typography variant="caption">
            Imagem a ser adicionada: <strong>{file.name}</strong>
          </Typography>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default UploadInput;
