import React, { useCallback, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ElectionStatus from "components/CompanyDashboard/Election/ElectionStatus/ElectionStatus";
import ElectionInfo from "components/CompanyDashboard/Election/ElectionInfo/ElectionInfo";

import * as uiActions from "../../../store/actions/uiActions";
import * as companyActions from "../../../store/actions/companyActions";
import ElectionCandidatesModal from "components/CompanyDashboard/Election/ElectionCandidatesModal";
import ElectionReportsModal from "components/CompanyDashboard/Election/ElectionReportsModal";
import ElectionActions from "components/CompanyDashboard/Election/ElectionActions/ElectionActions";
import { connect } from "react-redux";
import ElectionEmployeesModal from "components/CompanyDashboard/Election/ElectionEmployeesModal/ElectionEmployeesModal";

const Manager = ({ company, isLoading, dispatch, managerAccess }) => {
  const setManagerHandler = useCallback(() => {
    dispatch(companyActions.setManagerAccess());
  }, [dispatch]);

  useEffect(() => {
    setManagerHandler();
  }, [setManagerHandler]);

  const onSetCandidates = () => {
    dispatch(
      uiActions.showMqvModal(
        "Gerenciar Candidatos",
        <ElectionCandidatesModal maxWidth="600" />
      )
    );
  };

  const onSetEmployess = () => {
    dispatch(
      uiActions.showMqvModal(
        "Gerenciar Funcionarios",
        <ElectionEmployeesModal maxWidth="600" />
      )
    );
  };

  const onSetReport = () => {
    dispatch(
      uiActions.showMqvModal(
        "Relatorios da eleição",
        <ElectionReportsModal maxWidth="1024" />
      )
    );
  };

  return (
    <Box maxWidth={700} style={{ margin: "20px auto" }}>
      <Box
        mb={2}
        pb={2}
        style={{ borderBottom: "1px solid #ccc" }}
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h1">{company.election.name}</Typography>
        </Box>
        <Box textAlign={"right"}>
          <img
            style={{ width: "70px", marginRight: "8px" }}
            src={company.logoUrl}
            alt="Logo da empresa"
          />
          <br />
          {company.name}
          <br />
          CNPJ {company.cnpj}
        </Box>
      </Box>
      <Box pb={2} mb={2} style={{ borderBottom: "1px solid #ccc" }}>
        <ElectionStatus election={company.election} />
      </Box>
      <Box display="flex" mb={2}>
        <Box flexGrow={1}>
          <ElectionInfo
            subscribePeriodDateStart={company.election.subscribePeriodDateStart}
            subscribePeriodDateEnd={company.election.subscribePeriodDateEnd}
            subscribePeriodTimeStart={company.election.subscribePeriodTimeStart}
            subscribePeriodTimeEnd={company.election.subscribePeriodTimeEnd}
            electionDateStart={company.election.electionDateStart}
            electionDateEnd={company.election.electionDateEnd}
            electionTimeStart={company.election.electionTimeStart}
            electionTimeEnd={company.election.electionTimeEnd}
          ></ElectionInfo>
        </Box>
        <Box my={2} flexGrow={1}>
          <ElectionActions
            isLoading={isLoading}
            managerAccess={managerAccess}
            showCandidatesButton
            showReportButton
            showEmployeesButton
            setCandidates={() => onSetCandidates()}
            setEmployees={() => onSetEmployess()}
            setReport={() => onSetReport()}
          ></ElectionActions>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  hasErrors: state.company.hasErrors,
  isLoading: state.company.isLoading,
  managerAccess: state.company.managerAccess,
});

export default connect(mapStateToProps)(Manager);
