import React from "react";

import Typography from "@material-ui/core/Typography";

const PageTitle = (props) => {
  const styles = {};

  if (props.bolder) {
    styles.fontWeight = "bold";
  }

  return (
    <Typography variant="h3" style={styles}>
      {props.title}
    </Typography>
  );
};

export default PageTitle;
