import { Box, Button, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AreaForm from "./AreaForm/AreaForm";
import AreaList from "./AreaList/AreaList";

import * as companyActions from "../../../../store/actions/companyActions";
import * as uiActions from "../../../../store/actions/uiActions";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";

const ElectionAreasModal = ({ company, dispatch, isLoading, errorMessage }) => {
  const [areaToEdit, setAreaToEdit] = useState(null);
  const [areas, setAreas] = useState([]);

  const { election } = company;

  useEffect(() => {
    setAreas([...election.areas]);
  }, [election.areas]);

  const onNewAreaHandler = (newArea) => {
    setAreas([...areas, newArea]);
    setAreaToEdit(null);
  };

  const onUpdatedAreaHandler = (updatedArea) => {
    const updatedAreas = areas.map((area) => {
      if (area.areaId === updatedArea.areaId) {
        area = { ...updatedArea };
      }

      return area;
    });

    setAreas(updatedAreas);
    setAreaToEdit(null);
  };

  const onAreaEditRequestHandler = (editArea) => {
    setAreaToEdit(editArea);
  };

  const onAreaRemovedHandler = (removedAreaId) => {
    const updatedAreas = areas.filter((area) => area.areaId !== removedAreaId);

    setAreas(updatedAreas);
    setAreaToEdit(null);
  };

  const onSaveAreasHandler = () => {
    dispatch(companyActions.saveAreas(company, areas));
    dispatch(uiActions.closeMqvModal());
  };

  return (
    <Box my={3}>
      {election.isElectionPerArea ||
      (areaToEdit) ? (
        <Box mb={1}>
          <AreaForm
            area={areaToEdit}
            onNewArea={(newArea) => onNewAreaHandler(newArea)}
            onUpdatedArea={(updatedArea) => onUpdatedAreaHandler(updatedArea)}
          />
        </Box>
      ) : null}

      <Box mb={3}>
        <Divider />
      </Box>
      <Box my={3}>
        <AreaList
          areas={areas}
          onAreaEditRequest={(editArea) => onAreaEditRequestHandler(editArea)}
          onAreaRemoved={(removedAreaId) => onAreaRemovedHandler(removedAreaId)}
        />
      </Box>

      {errorMessage ? (
        <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
      ) : null}

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSaveAreasHandler}
          disabled={isLoading}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  isLoading: state.company.isLoading,
  errorMessage: state.company.errorMessage,
});

export default connect(mapStateToProps)(ElectionAreasModal);
