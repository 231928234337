import React from "react";

import { Typography, Box, IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/RemoveCircle";

const AreaList = ({ areas, onAreaEditRequest, onAreaRemoved }) => {
  let render = [];

  if (areas && areas.length > 0) {
    areas.forEach((area) => {
      const totalElects =
        parseInt(area.qtdEffectives) + parseInt(area.qtdSuplentes);

      render.push(
        <Box my={2} display="flex" alignItems="center" key={area.areaId}>
          <Box flexGrow={1}>
            <Typography variant="body1">
              Nome: {area.areaName}
              <br /> Id: {area.areaId}
              <br /> Qtd Eleitos: {totalElects}
            </Typography>
          </Box>

          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => onAreaEditRequest(area)}
            >
              <EditIcon />
            </IconButton>
          </Box>

          {!area.cannotBeDeleted ? (
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                style={{ color: "red" }}
                onClick={() => onAreaRemoved(area.areaId)}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      );
    });
  } else {
    render = (
      <Box display="flex" alignItems="center" justifyContent="center" py={3}>
        <Typography variant="body1">Ainda não existem areas</Typography>
      </Box>
    );
  }

  return render;
};

export default AreaList;
