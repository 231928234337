import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import NewPasswordBox from "../components/NewPasswordBox/NewPasswordBox";
import { Auth } from "aws-amplify";

export default function NewPassword() {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSentCodeHandler = async (username) => {
    await Auth.forgotPassword(username);
  }

  const onSubmitHandler = async (_, new_password, username,  verificationCode) => {
    //const user = isAuthenticated;

    Auth.forgotPasswordSubmit(username, verificationCode, new_password, {})
      .then((rst) => {
        setSuccess("Nova senha inserida com sucesso!, efetue o login novamente!")

        window.setTimeout(() => {
          history.push("/login");
        }, 500)
      })
      .catch((ex) => {
        console.log(ex);
        setError("Ocorreu um erro tente novamente");
      });
  };
  return (
      <NewPasswordBox onSubmitHandler={onSubmitHandler} onSentCode={onSentCodeHandler} successMessage={success} errorMessage={error} resetFlow/>
  );
}
