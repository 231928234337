import { Paper, Box, Typography } from "@material-ui/core";
import React from "react";
import ElectionInfo from "./ElectionInfo/ElectionInfo";
import ElectionActions from "./ElectionActions/ElectionActions";

import { connect } from "react-redux";

import * as uiActions from "../../../store/actions/uiActions";
import * as companyActions from "../../../store/actions/companyActions";

import ElectionStatus from "./ElectionStatus/ElectionStatus";

import ElectionConfigModal from "./ElectionConfigModal/ElectionConfigModal";
import Dialog from "../../UI/Modal/templates/Dialog/Dialog";
import { useHistory } from "react-router";
import ElectionPeriodModal from "./ElectionPeriodModal/ElectionPeriodModal";
import ElectionAreasModal from "./ElectionAreasModal/ElectionAreasModal";
import ElectionEmployeesModal from "./ElectionEmployeesModal/ElectionEmployeesModal";
import ElectionCandidatesModal from "./ElectionCandidatesModal";
import ElectionReportsModal from "./ElectionReportsModal";

const Election = ({ company, dispatch, isLoading }) => {
  const { election } = company;
  const history = useHistory();

  const onExcludeHandler = () => {
    dispatch(
      uiActions.showMqvModal(
        "Excluir Empresa",
        <Dialog
          message="Deseja realmente excluir a empresa?"
          onAccept={() => {
            dispatch(uiActions.closeMqvModal());
            dispatch(
              companyActions.deleteCompany(company, () => {
                history.push("/");
              })
            );
          }}
          onDecline={() => dispatch(uiActions.closeMqvModal())}
        />
      )
    );
  };

  const onSetConfig = () => {
    dispatch(uiActions.showMqvModal("Configurações", <ElectionConfigModal />));
  };

  const onSetPeriod = () => {
    dispatch(uiActions.showMqvModal("Periodo", <ElectionPeriodModal />));
  };

  const onSetAreas = () => {
    dispatch(
      uiActions.showMqvModal(
        "Gerenciar Areas",
        <ElectionAreasModal maxWidth="600" />
      )
    );
  };

  const onSetEmployess = () => {
    dispatch(
      uiActions.showMqvModal(
        "Gerenciar Funcionarios",
        <ElectionEmployeesModal maxWidth="600" />
      )
    );
  };

  const onSetCandidates = () => {
    dispatch(
      uiActions.showMqvModal(
        "Gerenciar Candidatos",
        <ElectionCandidatesModal maxWidth="600"/>
      )
    );
  };

  const onSetReport = () => {
    dispatch(
      uiActions.showMqvModal(
        "Relatorios da eleição",
        <ElectionReportsModal maxWidth="1024" />
      )
    );
  };

  return (
    <React.Fragment>
      <div>
        <Typography variant="h1">Eleição - {election.name}</Typography>
        <Box my={2}>
          <Paper>
            <Box display="flex" flexDirection="row">
              <Box p={3} flexGrow={1}>
                <ElectionInfo
                  subscribePeriodDateStart={
                    company.election.subscribePeriodDateStart
                  }
                  subscribePeriodDateEnd={
                    company.election.subscribePeriodDateEnd
                  }
                  subscribePeriodTimeStart={
                    company.election.subscribePeriodTimeStart
                  }
                  subscribePeriodTimeEnd={
                    company.election.subscribePeriodTimeEnd
                  }
                  electionDateStart={company.election.electionDateStart}
                  electionDateEnd={company.election.electionDateEnd}
                  electionTimeStart={company.election.electionTimeStart}
                  electionTimeEnd={company.election.electionTimeEnd}
                ></ElectionInfo>
              </Box>

              <Box p={3} flexGrow={3}>
                <ElectionActions
                  isLoading={isLoading}
                  excludeCompany={() => onExcludeHandler()}
                  showReportButton={true}
                  showEmployeesButton={
                    company.election.areas && company.election.areas.length > 0
                  }
                  showCandidatesButton
                  setConfig={() => onSetConfig()}
                  setPeriod={() => onSetPeriod()}
                  setArea={() => onSetAreas()}
                  setReport={() => onSetReport()}
                  setEmployees={() => onSetEmployess()}
                  setCandidates={() => onSetCandidates()}
                ></ElectionActions>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box my={2}>
          <Paper>
            <Box p={3}>
              <ElectionStatus
                election={company.election}
                employees={company.employees}
              ></ElectionStatus>
            </Box>
          </Paper>
        </Box>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasErrors: state.company.hasErrors,
  isLoading: state.company.isLoading,
});

export default connect(mapStateToProps)(Election);
