import React from "react";

import Layout from "../components/Layouts/ElectionLayout/ElectionLayout";
import Election from "../components/Election";

export default function Home() {
  return (
    <Layout>
      <Election/>
    </Layout>
  );
}
