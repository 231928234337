import React from "react";
import { Button, Box, colors } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const DeleteCompanyButton = withStyles({
  root: {
    backgroundColor: colors.red[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.red[700],
    },
  },
})(Button);

const ElectionButtonReport = withStyles({
  root: {
    backgroundColor: colors.purple[400],
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.purple[700],
    },
  },
})(Button);

const ElectionActions = ({
  setConfig,
  setPeriod,
  setCandidates,
  setEmployees,
  setArea,
  setReport,
  excludeCompany,
  isLoading,
  showAreaButton,
  showCandidatesButton,
  showEmployeesButton,
  showReportButton,
  managerAccess,
}) => {
  return (
    <React.Fragment>
      <Box>
        {!managerAccess ? (
          <Box py={1}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={setConfig}
              disabled={isLoading}
            >
              Configurações
            </Button>
          </Box>
        ) : null}
        {!managerAccess ? (
          <Box py={1}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={setPeriod}
              disabled={isLoading}
            >
              Periodo
            </Button>
          </Box>
        ) : null}

        {showEmployeesButton ? (
          <Box py={1}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={setEmployees}
              disabled={isLoading}
            >
              Funcionarios
            </Button>
          </Box>
        ) : null}

        {!managerAccess ? (
          <Box py={1}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={setArea}
              disabled={isLoading}
            >
              Areas
            </Button>
          </Box>
        ) : null}
      </Box>

      {showCandidatesButton ? (
        <Box py={1}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={setCandidates}
            disabled={(isLoading || !showEmployeesButton) && !managerAccess}
          >
            Candidatos
          </Button>
        </Box>
      ) : null}

      <Box py={1}>
        <ElectionButtonReport
          fullWidth
          color="secondary"
          variant="contained"
          onClick={setReport}
          disabled={
            (isLoading || !showReportButton || !showEmployeesButton) &&
            !managerAccess
          }
        >
          Relatórios
        </ElectionButtonReport>
      </Box>

      {!managerAccess ? (
        <Box py={1} style={{ position: "relative" }}>
          <DeleteCompanyButton
            fullWidth
            color="default"
            variant="contained"
            onClick={excludeCompany}
            disabled={isLoading}
          >
            Excluir Empresa
          </DeleteCompanyButton>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default ElectionActions;
