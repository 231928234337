export const SHOW_MQV_MODAL = "SHOW_MQV_MODAL";
export const SET_MQV_MODAL_TITLE = "SET_MQV_MODAL_TITLE";
export const CLOSE_MQV_MODAL = "CLOSE_MQV_MODAL";
export const SET_MQV_MODAL_SIZE = "SET_MQV_MODAL_SIZE";
export const SHOW_CANDIDATEPREVIEW_MODAL = "SHOW_CANDIDATEPREVIEW_MODAL";
export const CLOSE_CANDIDATEPREVIEW_MODAL = "CLOSE_CANDIDATEPREVIEW_MODAL";

export const showMqvModal = (title, component, config) => {
  return async (dispatch) => {
    dispatch({ type: SET_MQV_MODAL_TITLE, payload: title });
    dispatch({ type: SHOW_MQV_MODAL, payload: component });
  };
};

export const showCandidatePreviewModal = (value) => {
  return async (dispatch) => {
    dispatch({ type: SHOW_CANDIDATEPREVIEW_MODAL, payload: value });
  };
};

export const changeMqvModalSize = (size) => {
  return async (dispatch) => {
    dispatch({ type: SET_MQV_MODAL_SIZE, payload: size });
  };
}

export const closeMqvModal = () => ({
  type: CLOSE_MQV_MODAL,
  payload: false,
});
