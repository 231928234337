import moment from "moment-timezone";

import "moment/locale/pt-br";

moment.locale("pt-br");
moment.tz.setDefault("America/Sao_Paulo");

export function getUTCDate(date, showTime, showSeconds) {
  if (date === "--/--/----") {
    return date;
  }
  return moment(date).format(
    showTime ? "DD/MM/YYYY HH:mm" + (showSeconds ? ":ss" : "") : "DD/MM/YYYY"
  );
}
export function getUTCDateObj(date, time) {
  let format = "DD/MM/YYYY";
  let fulldate = date;

  if (date && date.indexOf("-") !== -1) {
    format = "YYYY-MM-DD";
  }

  if (time) {
    format += " HH:mm:ss";
    fulldate += time;
  }

  return moment(fulldate, format).local();
}

export function getUTCNow(formated) {
  if (formated) {
    return getUTCDate(moment(), true);
  }

  return moment().local();
}

export function getTimeStamp(date) {
  return moment(date).unix();
}

export function getISOString(date) {
  return moment(date).toISOString();
}

export function isInPeriod(date1, date2, date) {
  let inPeriod = false;

  if (moment(date).isBetween(date1, date2)) {
    inPeriod = true;
  }

  return inPeriod;
}

export function getDateStringObj(date) {
  return {
    dateString: moment(date).format("LL"),
    dateTime: moment(date).format("LT"),
  };
}

export function getFullDateFormated(date, time, delemiter) {
  return getUTCDate(date) + " " + delemiter + " " + time;
}

export function isInAfter(date1, date2) {
  let isAfter = false;

  if (moment(date1).isAfter(date2)) {
    isAfter = true;
  }

  return isAfter;
}

export function getLocale() {
  return moment.locale();
}
