import React from "react";

import { Box, Button } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import { useFormik } from "formik";
import * as yup from "yup";

import TextInput from "../../../../UI/TextInput/TextInput";

const validationSchema = yup.object({
  areaName: yup.string().required("Requerido"),
  qtdEffectives: yup.string().required("Requerido"),
  qtdSuplentes: yup.string().required("Requerido"),
});

export default function AreaForm({ area, onNewArea, onUpdatedArea }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      areaName: area ? area.areaName : "",
      qtdEffectives: area ? area.qtdEffectives : "",
      qtdSuplentes: area ? area.qtdSuplentes : "",
    },
    validationSchema: validationSchema,
    onSubmit: (data, { resetForm }) => {
      resetForm({});

      if (area && area.areaId) {
        onUpdatedArea({
          ...area,
          ...data,
        });

        return;
      }

      onNewArea({
        ...data,
        areaId: uuidv4(),
        votes: [],
        employees: [],
        candidates: [],
      });
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box display={"flex"} justifyContent={"space-around"}>
          <Box p={1}>
            <TextInput
              fieldId="areaName"
              label="Nome"
              formik={formik}
              disabled={area && area.cannotBeDeleted}
            ></TextInput>
          </Box>

          <Box p={1}>
            <TextInput
              fieldId="qtdEffectives"
              label="Qtd Efetivos"
              formik={formik}
            ></TextInput>
          </Box>

          <Box p={1}>
            <TextInput
              fieldId="qtdSuplentes"
              label="Qtd Suplentes"
              formik={formik}
            ></TextInput>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button variant="contained" type="submit" color="secondary">
            {area && area.areaId ? "Atualizar" : "Adicionar"}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
