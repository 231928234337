import React, { useMemo, useState } from "react";

import { TextField } from "@material-ui/core";

const TextInput = ({
  label,
  fieldId,
  formik,
  value = "",
  fullWidth = true,
  variant = "outlined",
  type = "text",
  disabled,
  onChange = null,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const InputProps = {};
  const defaultValue = useMemo(() => {
    return formik ? formik.values[fieldId] : inputValue;
  }, [formik, inputValue, fieldId]);

  if (type === "date" || type === "time") {
    InputProps.shrink = true;
  }

  const handleChange = (ev) => {
    if (!formik) {
      setInputValue(ev.target.value);
    }
    if (onChange) {
      onChange(ev.target.value);
    }

    if (formik) {
      formik.handleChange(ev);
    }
  };

  const isError = () => {
    if (formik) {
      return formik.touched[fieldId] && Boolean(formik.errors[fieldId]);
    }

    return false;
  };

  const helperText = () => {
    if (formik) {
      return formik.touched[fieldId] && formik.errors[fieldId];
    }

    return false;
  };

  return (
    <TextField
      fullWidth={fullWidth}
      id={fieldId}
      name={fieldId}
      type={type}
      value={defaultValue}
      label={label}
      onChange={handleChange}
      error={isError()}
      helperText={helperText()}
      variant={variant}
      InputLabelProps={InputProps}
      disabled={disabled}
    />
  );
};

export default TextInput;
