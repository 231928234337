import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Box, CircularProgress } from "@material-ui/core";

import ErrorMessage from "../../UI/ErrorMessage/ErrorMessage";

import Form from "./Form/Form";
import * as companyAction from "../../../store/actions/companyActions";

const CompanyForm = ({
  formData,
  dispatch,
  hasErrors,
  isLoading,
  hasSuccess,
}) => {
  const history = useHistory();

  const handleSubmit = (
    { data, isElectionPerArea, requestApplicationForm },
    file
  ) => {
    const requestBody = {
      companyId: data.companyId,
      name: data.name,
      surname: data.surname,
      cnpj: data.cnpj,
      city: data.city,
      state: data.state,
    };

    if (data.companyId) {
      dispatch(companyAction.updateCompany(data.companyId, requestBody, file));
    } else {
      const election = {
        electionName: data.electionName,
        extraInfo: data.electionExtraInfo,
        isElectionPerArea: isElectionPerArea,
        requestApplicationForm: requestApplicationForm,
      };

      dispatch(
        companyAction.createCompany(
          { requestBody, election, file },
          (createdCompany) => {
            dispatch(
              companyAction.uploadCompanyLogo(createdCompany, file)
            ).then(() => {
              dispatch(
                companyAction.setCompany(createdCompany, () => {
                  history.push("/company/" + createdCompany.companyId);
                })
              );
            });
          }
        )
      );
    }
  };

  let rendered = [
    <Form
      submit={handleSubmit}
      errorMessage={hasErrors ? hasErrors.message : false}
      initialValue={formData}
      isLoading={isLoading}
      key="company-form"
    />,
  ];

  if (isLoading) {
    rendered.push(
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        key="company-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (hasErrors) {
    rendered.push(
      <Box py={3}>
        <ErrorMessage>{hasErrors}</ErrorMessage>
      </Box>
    );
  }

  return rendered;
};

const mapStateToProps = (state) => ({
  hasErrors: state.company.hasErrors,
  isLoading: state.company.isLoading,
  hasSuccess: state.company.hasSuccess,
});

export default connect(mapStateToProps)(CompanyForm);
