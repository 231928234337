import * as uuid from "uuid";

/**
 * Election
 */

export function setElectionResult(state) {
  const company = state.company;
  const electionResult = {
    areas: {},
    total: {
      employeesCount: 0,
      votes: 0,
      percent: 0.0,
    },
  };

  company.election.areas.forEach((area) => {
    electionResult.areas[area.areaId] = {
      employeesCount: 0,
      votes: 0,
      percent: 0.0,
      candidates: {},
    };

    area.employees.forEach((employee) => {
      if (employee.votedIn) {
        if (electionResult.areas[area.areaId].candidates[employee.votedIn]) {
          electionResult.areas[area.areaId].candidates[employee.votedIn]++;
        } else {
          electionResult.areas[area.areaId].candidates[employee.votedIn] = {
            votes: 0,
          };
        }
      }

      if (employee.votedIn) {
        electionResult.areas[area.areaId].votes++;
        electionResult.total.votes++;
      }

      electionResult.areas[area.areaId].employeesCount++;
      electionResult.employeesCount++;
    });

    electionResult.areas[area.areaId].percent = (
      (electionResult.areas[area.areaId].votes * 100) /
      electionResult.areas[area.areaId].employeesCount
    ).toFixed(1);
  });

  electionResult.percent = (
    (electionResult.votes * 100) /
    electionResult.employeesCount
  ).toFixed(1);

  state.company.electionResult = electionResult;

  return state;
}

export function updateAreaToCreateEmployee(areas, payload, isAll) {
  const newAreas = areas.map((area) => {
    if (area.areaId === payload.areaId) {
      if (isAll) {
        area.employees = payload;
      } else {
        area.employees.push(
          payload.subscribeNumber
            ? payload
            : {
                ...payload,
                votedIn: null,
                isCandidate: false,
                photoUrl: null,
                isSubscribeApproved: false,
                subscribeNumber: uuid.v1(),
              }
        );
      }
    }

    return area;
  });

  return newAreas;
}

/**
 * Employee
 **/
export function createEmployee(state, payload) {
  const { data, isAll } = payload;
  const { company } = state;

  if (company.election.areas.length > 0) {
    state.company.election.areas = updateAreaToCreateEmployee(
      company.election.areas,
      data,
      isAll
    );
  }

  return state;
}

export function updateEmployee(state, payload) {
  const areas = state.areas;
  const areaId = payload.areaId;

  let updatedState = state;

  const foundIndex = areas[areaId].employees.findIndex(
    (e) => e.subscribeNumber === payload.subscribeNumber
  );

  if (foundIndex !== -1) {
    areas[areaId].employees[foundIndex] = payload;
  }

  return updateArea(updatedState, {
    employees: areas[areaId].employees,
    areaId: areaId,
  });
}

export function getEmployee(state, payload) {
  const company = state.company;
  const { areaId, employeeUsername, employeePassword } = payload;

  if (company && company.election.areas && company.election.areas.length > 0) {
    const area = company.election.areas.filter(
      (area) => area.areaId === areaId
    )[0];
    const employee = area.find(
      (emp) =>
        emp.username === employeeUsername && emp.password === employeePassword
    );

    if (!employee) {
      throw new Error("Employee not found");
    }

    return employee;
  }
}

export function deleteEmployee(state, payload) {
  const { areas } = state;
  const { areaId, username, isDeleteAll } = payload;

  const employess = isDeleteAll
    ? []
    : areas[areaId].employees.filter(
        (employee) => employee.username !== username
      );

  return updateArea(state, {
    areaId,
    employess,
  });
}

/**
 * Area
 **/

export function updateArea(state, payload) {
  const areaId = payload.areaId;

  const newAreas = state.company.election.areas.map((a) => {
    if (a.areaId === areaId) {
      return {
        ...a,
        ...payload,
      };
    }

    return a;
  });

  return newAreas;
}

export function updateAreas(mappedAreas, areasList, areaId) {
  for (var i = 0; i < areasList.length; i++) {
    if (areasList[i].areaId === areaId) {
      areasList[i] = mappedAreas[areaId];

      break;
    }
  }

  return areasList;
}
