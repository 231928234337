import * as actions from "../actions/companyActions";

const initialState = {
  list: null,
  company: null,
  areas: {},
  isLoading: false,
  hasErrors: false,
  hasSuccess: false,
  notifications: [],
  notification: null,
  managerAccess: false,
  conflicts: [],
  importationInfo: null,
  errorMessage: null,
};

let errorMessage = "Ocorreu um erro ao processar sua solicitação anterior!";

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_MANAGER_ACCESS":
      return {
        ...state,
        managerAccess: action.payload,
      };
    case "NEW_MESSAGE":
      if (action.payload.type === "NEW_VOTE") {
        const data = action.payload.data;

        const company = state.company;
        const newArea = company.election.areas;

        if (data && data.companyId && data.companyId === company.companyId) {
          for (var areaIndex = 0; newArea.length >= areaIndex; areaIndex++) {
            if (newArea[areaIndex].areaId === data.areaId) {
              var employeeIndex = 0;
              for (
                ;
                newArea[areaIndex].employees.length >= employeeIndex;
                employeeIndex++
              ) {
                if (
                  newArea[areaIndex].employees[employeeIndex]
                    .subscribeNumber === data.subscribeNumber
                ) {
                  newArea[areaIndex].employees[employeeIndex].votedIn =
                    data.votedIn;
                  break;
                }
              }

              break;
            }
          }

          company.election.areas = newArea;

          return {
            ...state,
            company: { ...company },
          };
        }

        return {
          ...state,
        };
      }

      if (action.payload.type === "UPDATE_STATUS") {
        const { election, companyId } = action.payload;

        const company = state.company;
        let newNotification = [];

        const companies = (state.list || []).map((c) => {
          if (c.companyId === companyId) {
            c.election.status = election.status;

            if (c.election.status === c.electionStates.PENDING) {
              newNotification.push({
                title: "Uma eleição necessita de sua atenção!",
                message:
                  "A eleição " +
                  c.election.name +
                  " está em status Pendente, você precisará tomar uma ação sobre isso",
                color: "black",
                link: "/company/" + c.companyId,
                createdAt: new Date(),
              });
            }
          }

          return c;
        });

        if (company && company.companyId === companyId) {
          company.election.status = election.status;
          company.election.subscribePeriodDateStart =
            election.subscribePeriodDateStart;
          company.election.subscribePeriodTimeStart =
            election.subscribePeriodTimeStart;
          company.election.electionDateStart = election.electionDateStart;
          company.election.electionTimeStart = election.electionTimeStart;
        }

        console.log(newNotification);

        return {
          ...state,
          list: companies,
          company: { ...company },
          notifications: [...newNotification, ...state.notifications],
        };
      }

      return {
        ...state,
      };

    case actions.GET_COMPANIES:
    case actions.GET_COMPANY:
    case actions.DELETE_COMPANY:
    case actions.CREATE_COMPANY:
    case actions.UPDATE_COMPANY:
    case actions.UPLOAD_COMPANY_LOGO:
    case actions.UPDATE_COMPANY_LOGO_URL:
    case actions.SAVE_ELECTION_CONFIG:
    case actions.SAVE_ELECTION:
    case actions.SAVE_ELECTION_PERIOD:
    case actions.SAVE_AREA:
    case actions.IMPORT_EMPLOYEES:
      return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasErrors: false,
      };

    case actions.GET_COMPANIES_ERROR:
      errorMessage = "Não foi possível obter as empresas";
      break;
    case actions.GET_COMPANY_ERROR:
      errorMessage = "Não foi possível obter a empresa";
      return {
        ...state,
        isLoading: false,
        hasErrors: errorMessage,
        hasSuccess: false,
      };
    case actions.DELETE_COMPANY_ERROR:
      errorMessage = "Não foi possível deletar a empresa " + state.company.name;
      break;
    case actions.UPDATE_COMPANY_ERROR:
      errorMessage =
        "Não foi possível atualizar a empresa " + state.company.name;
      break;
    case actions.UPLOAD_COMPANY_LOGO_ERROR:
    case actions.UPDATE_COMPANY_LOGO_URL_ERROR:
      errorMessage =
        "Não foi possível trocar o logo da empresa" + state.company.name;
      break;
    case actions.SAVE_ELECTION_CONFIG_ERROR:
      errorMessage =
        "Não foi possível salvar as configurações da empresa" +
        state.company.name;
      break;
    case actions.SAVE_ELECTION_PERIOD_ERROR:
      errorMessage =
        "Não foi possível atualizar os periodos de eleição/inscriçao da empresa" +
        state.company.name;
      break;
    case actions.SAVE_ELECTION_ERROR:
      errorMessage =
        "Não foi possível salvar a eleição da empresa " + state.company.name;
      break;
    case actions.IMPORT_EMPLOYEES_ERROR:
      errorMessage = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMessage: errorMessage,
        hasSuccess: false,
      };
    case actions.SAVE_AREA_ERROR:
      state.notifications.push({
        title: "Ocorreu um erro ao processar sua soliticação",
        message: errorMessage,
        color: "red",
        createdAt: new Date(),
      });

      return {
        ...state,
        isLoading: false,
        hasErrors: action.payload,
        notifications: state.notifications,
        hasSuccess: false,
        conflicts: [],
        importationInfo: null,
      };
    case actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        hasErrors: false,
      };
    case actions.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        isLoading: false,
        hasErrors: false,
      };
    case actions.SET_COMPANY:
      const areas = {};

      action.payload.election.areas.forEach((a) => (areas[a.areaId] = a));

      return {
        ...state,
        company: action.payload,
        areas: areas,
        isLoading: false,
        hasErrors: false,
      };
    case actions.DELETE_COMPANY_SUCCESS:
      const companies = state.list
        ? state.list.filter(
            (company) => company.companyId !== action.payload.companyId
          )
        : [];

      return {
        ...state,
        list: companies,
        isLoading: false,
        hasErrors: false,
      };
    case actions.CREATE_COMPANY_SUCCESS:
      state.list.push(action.payload);

      state.notifications.push({
        title: "Nova empresa: ",
        message: "Empresa criada com sucesso !",
        color: "green",
        createdAt: new Date(),
      });

      return {
        ...state,
        company: action.payload,
        isLoading: false,
        hasErrors: false,
      };
    case actions.UPDATE_COMPANY_SUCCESS:
      state.notifications.push({
        title: "Atualização de Empresa",
        message: "Empresa atualizada com sucesso !",
        color: "green",
        createdAt: new Date(),
      });
      return {
        ...state,
        company: { ...state.company, ...action.payload },
        isLoading: false,
        hasErrors: false,
        hasSuccess: true,
      };
    case actions.UPLOAD_COMPANY_LOGO_SUCCESS:
      state.notifications.push({
        title: "Atualização de Empresa: ",
        message: "Logo da Empresa atualizado com sucesso !",
        color: "green",
        createdAt: new Date(),
      });
      return {
        ...state,
        company: { ...state.company, ...action.payload },
        isLoading: false,
        hasErrors: false,
        notifications: state.notifications,
      };
    case actions.UPDATE_COMPANY_LOGO_URL_SUCCESS:
      return {
        ...state,
        company: { ...state.company, logoUrl: action.payload },
        isLoading: false,
        hasErrors: false,
      };
    case actions.GET_COMPANY_LOGO:
      return {
        ...state,
        company: action.payload,
        isLoading: false,
        hasErrors: false,
        hasSuccess: false,
      };
    case actions.REMOVE_MESSAGES:
      return {
        ...state,
        hasErrors: false,
        hasSuccess: false,
      };
    case actions.IMPORT_EMPLOYEES_SUCCESS:
      const mappedAreas = {};

      action.payload.areas.forEach((a) => (mappedAreas[a.areaId] = a));

      return {
        ...state,
        company: {
          ...state.company,
          election: { ...state.company.election, areas: action.payload.areas },
        },
        areas: mappedAreas,
        hasErrors: false,
        isLoading: false,
        hasSuccess: false,
        conflicts: action.payload.conflicts,
        importationInfo:
          " Importação Concluída: " +
          action.payload.quantity +
          " funcionários importados",
        errorMessage: null,
      };
    case actions.SET_CONFLICTS:
      return {
        ...state,
        conflicts: action.payload,
      };
    case actions.SAVE_ELECTION_CONFIG_SUCCESS:
    case actions.SAVE_ELECTION_SUCCESS:
    case actions.SAVE_ELECTION_PERIOD_SUCCESS:
      state.notifications.push({
        title: "Atualização de Eleição:",
        message:
          "Eleição da empresa " +
          state.company.name +
          " atualizada com sucesso",
        color: "green",
        createdAt: new Date(),
      })
      return {
        ...state,
        company: {
          ...state.company,
          election: { ...state.company.election, ...action.payload },
        },
        isLoading: false,
        hasErrors: false,
        hasSuccess: true,
        notifications: state.notifications,
      };
    case actions.SAVE_AREA_SUCCESS:
      const newCompanies = state.list
        ? state.list.map((company) => {
            if (company.companyId === action.payload.companyId) {
              company.election.areas = action.payload.areas;
            }

            return company;
          })
        : [];

      const newCompany = {
        ...state.company,
        election: { ...state.company.election, areas: action.payload.areas },
      };

      state.notifications.push({
        title: "Atualização de Eleição: ",
        message: "Areas inseridas/atualizadas com sucesso !",
        color: "green",
        createdAt: new Date(),
      })

      return {
        ...state,
        company: newCompany,
        list: newCompanies,
        isLoading: false,
        hasErrors: false,
        hasSuccess: true,
        notifications: state.notifications,
      };
    case actions.RESET_EMPLOYEE_MESSAGES:
      return {
        ...state,
        importationInfo: null,
        conflicts: [],
        errorMessage: null,
      };
    default:
      return state;
  }
}
