import { createTheme } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors"

const pxToRem = (px) => {
  const htmlFontSize = 16;
  const coef = 1;

  return `${(px / htmlFontSize) * coef}rem`;
}

const theme = new createTheme({
  palette: {
    primary: blueGrey,
    secondary: {
      main: "#535353",
    },
  },
  typography: {
    h1: {
      fontSize: pxToRem(36),
      fontFamily: "Roboto Condensed"
    },

    h2: {
      fontSize: pxToRem(36),
      fontFamily: "Roboto Condensed"
    },

    h3: {
      fontSize: pxToRem(24),
      fontFamily: "Roboto",
      fontWeight: "lighter"
    }
  }
});

export default theme;
