import { Storage } from "aws-amplify";


const s3PublicUrl = "https://" + process.env.REACT_APP_AWS_S3_BUCKET + ".s3.amazonaws.com/public/";

export async function s3Upload(file, folder) {
  const filename = `${folder ? folder + "/" : ""}${Date.now()}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type,
    level: "public"
  });

  return s3PublicUrl + stored.key;
}