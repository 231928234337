import React from "react";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

const InternalErrorMessage = ({ err }) => {
  let message = "Ocorreu um erro interno, contate o administrador.";

  if (err && err.response && err.response.status === 401) {
    message = "Sua sessão expirou, faça login novamente, redirecionando ....";
  }
  
  return <ErrorMessage>{message}</ErrorMessage>;
};

export default InternalErrorMessage;
