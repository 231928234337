import { Box, Button, Paper, Typography } from "@material-ui/core";
import CandidateList from "components/CompanyDashboard/Election/ElectionCandidates/CandidateList";
import AppModal from "components/UI/Modal/Modal";
import { getFullDateFormated } from "components/UI/utils";
import React, { useState } from "react";
import OtherVotes from "./OtherVotes";


const Votation = ({ company, employee, area, onVote, hasErrors }) => {
  const [voteModalOpen, setVoteModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const candidates = area.employees.filter(
    (employee) => employee.isCandidate && employee.isSubscribeApproved
  );

  const onOtherVoteHandler = (type) => {
    onVote(type);
  };

  const onCloseVoteModalHandler = () => {
    setVoteModalOpen(false);
    setSelectedCandidate(null);
  };

  const onVoteHandler = (candidate) => {
    setVoteModalOpen(true);
    setSelectedCandidate(candidate);
  };

  const onConfirmVoteHandler = () => {
    const candidateSubNumber = selectedCandidate.subscribeNumber;
    onVote(candidateSubNumber);
    onCloseVoteModalHandler();
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        px={2}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box mr={3} display="flex" alignItems="center" justifyContent="center">
              {company.logoUrl ? (
                <img
                  style={{ width: "70px" }}
                  src={company.logoUrl}
                  alt="Logo da empresa"
                />
              ) : null}
            </Box>
            <Box>
              <Box>
                <Typography variant="caption">
                  Empresa: {company.name} - CNPJ {company.cnpj}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h1">{company.election.name}</Typography>
                <Typography variant="subtitle1">
                  Periodo Eleição:{" "}
                  {getFullDateFormated(
                    company.election.electionDateStart,
                    company.election.electionTimeStart,
                    "ás"
                  )}{" "}
                  até{" "}
                  {getFullDateFormated(
                    company.election.electionDateEnd,
                    company.election.electionTimeEnd,
                    "ás"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box textAlign="right">
            <Typography variant="body1">
              Olá bem vindo, {employee.name}
            </Typography>
            <Typography variant="caption">{company.city}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" px={2}>
        <Box flexGrow={1}>
          <Box my={2}>
            <Paper>
              {hasErrors ? (
                <Box py={2}>
                  <Box textAlign={"center"}>
                    <Typography variant="body1">
                      Não foi possível computar seu voto, tente novamente !
                    </Typography>
                  </Box>
                </Box>
              ) : null}

              {!employee.votedIn ? (
                <Box py={2}>
                  {candidates.length > 0 ? (
                    <>
                      <AppModal
                        isOpen={voteModalOpen}
                        maxWidth={400}
                        closed={onCloseVoteModalHandler}
                      >
                        {selectedCandidate ? (
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <img
                                src={
                                  selectedCandidate.photoUrl ||
                                  "/images/no-photo.jpg"
                                }
                                alt="Foto do candidato"
                                width="70%"
                              />
                            </Box>
                            <Box textAlign="center" py={6}>
                              <Typography variant="h5">
                                Deseja confirmar o seu voto em
                                <br />
                                <strong>{selectedCandidate.name}</strong> ?
                              </Typography>
                            </Box>
                            <Box display="grid" mt={3}>
                              <Box py={2} justifySelf="stretch">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={onConfirmVoteHandler}
                                  fullWidth
                                >
                                  Sim
                                </Button>
                              </Box>
                              <Box py={2} justifySelf="stretch">
                                <Button
                                  variant="contained"
                                  color="default"
                                  onClick={onCloseVoteModalHandler}
                                  fullWidth
                                >
                                  Cancelar
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        ) : null}
                      </AppModal>
                      <CandidateList
                        isAlphabetical={
                          company.election.isAlphabeticCandidateListOrder
                        }
                        isShowSurname={
                          company.election.isShowSurnameCandidateList
                        }
                        onVote={(candidate) => {
                          onVoteHandler(candidate);
                        }}
                        list={candidates}
                      />
                      <OtherVotes onOtherVote={onOtherVoteHandler} />
                    </>
                  ) : (
                    <Box>
                      <Typography>
                        Não existem candidatos a serem exibidos.
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box py={7}>
                  <Box textAlign={"center"}>
                    <Typography variant="body1">
                      SEU VOTO FOI COMPUTADO COM SUCESSO!
                      <br />
                      <strong>Evento:</strong> {company.election.name}
                      <br />
                      <strong>Data:</strong> {employee.votedInTime}
                      <br />
                      <strong>IP:</strong> {employee.votedInIp}
                      <br />
                      <strong>Protocolo:</strong> {employee.subscribeNumber}
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} mt={3}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => window.location.reload()}
                    >
                      Sair
                    </Button>
                  </Box>
                </Box>
              )}
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Votation;
