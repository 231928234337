import { Box } from "@material-ui/core";
import React, {  useEffect, useState } from "react";
import { connect } from "react-redux";
import CandidateList from "./CandidateList";

import * as companyActions from "../../../../store/actions/companyActions";
import * as uiActions from "../../../../store/actions/uiActions";

import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";
import CandidatePhoto from "./CandidateList/CandidatePhoto";

/*import EnrollmentVoucher from "../ElectionCandidates/EnrollmentVoucher";
import ReactPDF, {  } from "@react-pdf/renderer";
*/
const ElectionCandidatesModal = ({
  company,
  dispatch,
  isLoading,
  errorMessage,
  managerAccess,
}) => {
  const [itemToEdit, setItemToEdit] = useState(null);
  const [areas, setItems] = useState([]);
  const [section, setSection] = useState("list");

  const onUpdatedItemHandler = (updatedEmployee) => {
    dispatch(companyActions.updateEmployee(company.companyId, updatedEmployee));

    setSection("list");
    setItemToEdit(null);
  };

  const onEmployeeAproveRequestHandler = (employee) => {
    const updatedEmployee = {
      ...employee,
      isSubscribeApproved: !employee.isSubscribeApproved,
    };

    onUpdatedItemHandler(updatedEmployee);
  };
  const onEmployeeUploadPhotoRequestRequestHandler = (employee) => {
    setSection("candidatePhoto");
    setItemToEdit(employee);
  };

  const onCancelHandler = () => {
    setSection("list");
    setItemToEdit(null);
  };

  const onPreviewHandler = () => {
    dispatch(uiActions.showCandidatePreviewModal(true));
  };

  useEffect(() => {
    setItems(company.election.areas);
  }, [company])

  return (
    <Box my={3}>
      <Box mb={1}>
        {section === "candidatePhoto" ? (
          <CandidatePhoto
            employee={itemToEdit}
            onCancel={() => onCancelHandler()}
          />
        ) : null}
        {section === "list" ? (
          <>
            <CandidateList
              managerAccess={managerAccess}
              areas={areas}
              company={company}
              onEmployeeAproveRequest={onEmployeeAproveRequestHandler}
              onEmployeeUploadPhotoRequest={
                onEmployeeUploadPhotoRequestRequestHandler
              }
              onPreview={onPreviewHandler}
            />

            {errorMessage ? (
              <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  isLoading: state.company.isLoading,
  errorMessage: state.company.errorMessage,
  managerAccess: state.company.managerAccess
});

export default connect(mapStateToProps)(ElectionCandidatesModal);
