import { Box, Typography, CircularProgress, Button } from "@material-ui/core/";
import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";

import VotantsTable from "./VotantsTable";
import NoVotantsTable from "./NoVotantsTable";
import ResultTable from "./ResultTable";
import CandidatesTable from "./CandidatesTable";
import EmployeesTable from "./EmployeesTable";
import ReportFilter from "../../../UI/Table/ReportFilter";

import * as companyServices from "../../../../services/company";
import { PrintButton } from "./PrintReport";
import { getUTCDate } from "components/UI/utils";
import { JSONToCSVConvertor } from "libs/jsonToCsv";
import * as reportColumns from "./columns";
import { REPORT_ENUM, REPORT_PROPERTIES, REPORT_TYPES } from "enums/report";

const termExistImObject = (item, searchTerm) => {
  let exists = false;
  let itemKeys = Object.keys(item);

  const term = searchTerm.toLowerCase();

  for (var itemKey of itemKeys) {
    let property = item[itemKey];
    property = typeof property == "string" ? property.toLowerCase() : property;

    if (typeof property == "string") {
      if (property.indexOf(term) !== -1) {
        exists = true;
        break;
      }
    } else {
      if (property === term) {
        exists = true;
        break;
      }
    }
  }

  return exists;
};

const ElectionReportsModal = ({ company }) => {
  const [reportType, setReportType] = useState(
    company.election.status === company.electionStates.VERIFICATED
      ? REPORT_TYPES.ELECTION_RESULTS
      : REPORT_TYPES.NO_VOTANTS
  );
  const [electionResult, setElectionResult] = useState(null);
  const [filtredRows, setRows] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  const filterOptions = [
    { text: "Votantes", value: REPORT_TYPES.VOTANTS },
    { text: "Não Votantes", value: REPORT_TYPES.NO_VOTANTS },
    { text: "Candidatos", value: REPORT_TYPES.CANDIDATES },
    { text: "Funcionarios", value: REPORT_TYPES.EMPLOYEES },
  ];

  if (company.election.status === company.electionStates.VERIFICATED) {
    filterOptions.push({
      text: "Resultado da eleição",
      value: REPORT_TYPES.ELECTION_RESULTS,
    });
  }

  const getRowsFromResults = useCallback(
    (rType, property, searchTerm, rst) => {
      const rows = [];
      const result = electionResult;

      let nulls = 0;
      let whites = 0;
      let sumCompanyEmployees = 0;
      let totalVotes = 0;

      Object.keys(result.areas).forEach((areaId) => {
        const area = result.areas[areaId];

        sumCompanyEmployees += area.totalEmployees;
        nulls += area.nulls;
        whites += area.whites;
        totalVotes += area.votes;

        area[property].forEach((item) => {
          if (searchTerm) {
            if (termExistImObject(item, searchTerm)) {
              rows.push(item);
            }
          } else {
            rows.push(item);
          }
        });

        if (
          rType === REPORT_TYPES.ELECTION_RESULTS &&
          rows.length > 0 &&
          !searchTerm
        ) {
          rows.push({
            registration: "-",
            name: "Brancos e Nulos",
            surname: "-",
            areaName: area.name,
            department: "-",
            votes: area.nulls + area.whites,
            votesPercent: (
              ((area.nulls + area.whites) * 100) /
              area.votes
            ).toFixed(1),
            isNotEmployee: true,
          });
          rows.push({
            registration: "-",
            name: "Total Votos",
            department: "-",
            surname: "-",
            areaName: area.name,
            votes: area.votes,
            votesPercent: ((area.votes * 100) / area.totalEmployees).toFixed(1),
            isNotEmployee: true,
          });
        }
      });

      if (
        rType === REPORT_TYPES.ELECTION_RESULTS &&
        company.election.isElectionPerArea &&
        rows.length > 0 &&
        !searchTerm
      ) {
        rows.push({
          registration: "-",
          name: "Total Brancos e Nulos",
          surname: "-",
          areaName: "Todas",
          votes: nulls + whites,
          votesPercent: (((nulls + whites) * 100) / totalVotes).toFixed(1),
          isNotEmployee: true,
        });

        rows.push({
          registration: "-",
          name: "Total Geral Votos",
          surname: "-",
          areaName: "Todas",
          votes: totalVotes,
          votesPercent: ((totalVotes * 100) / sumCompanyEmployees).toFixed(1),
          isNotEmployee: true,
        });
      }

      return rows;
    },
    [company, electionResult]
  );

  const getElectionResult = useCallback(async () => {
    const result = await companyServices.getElectionResult(company.companyId);
    setElectionResult(result);
  }, [company.companyId]);

  useEffect(() => {
    getElectionResult();
  }, [getElectionResult]);

  useEffect(() => {
    if (electionResult) {
      setRows(
        getRowsFromResults(
          reportType,
          REPORT_PROPERTIES[reportType],
          currentSearchTerm
        )
      );
    }
  }, [electionResult, getRowsFromResults, reportType, currentSearchTerm]);

  const changeReportType = (filter) => {
    const type = filter.report_type || reportType;
    const searchTerm = filter.search_term || "";

    setReportType(type);
    setCurrentSearchTerm(searchTerm);
  };

  if (!electionResult) {
    return (
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        key="company-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  let render = [
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent="center"
      flexWrap="wrap"
      key="report-list"
    >
      <Box flexGrow={1}>
        <ReportFilter
          options={filterOptions}
          reportTypeSelectedValue={reportType}
          autoSearch
          onChange={(filter) => {
            changeReportType(filter);
          }}
        />
      </Box>
      <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
        {filtredRows.length > 0 ? (
          <>
            <Box px={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  JSONToCSVConvertor(
                    filtredRows,
                    REPORT_ENUM[reportType],
                    reportColumns[reportType],
                    true
                  )
                }
              >
                Baixar CSV
              </Button>
            </Box>
            <PrintButton
              reportConfig={{
                imageUrl: company.logoUrl,
                companyName: company.name,
                companyCNPJ: company.cnpj,
                electionPeriod:
                  getUTCDate(company.election.electionDateStart) +
                  " " +
                  company.election.electionTimeStart +
                  " até " +
                  getUTCDate(company.election.electionDateEnd) +
                  " " +
                  company.election.electionTimeEnd,
                electionName: company.election.name,
                reportType: reportType,
                REPORT_TYPES: REPORT_TYPES,
                rows: filtredRows,
              }}
            />
          </>
        ) : null}
      </Box>
    </Box>,
  ];

  const sortCandidates = (ea, eb) => {
    const isAfter = ea.subscriptionNumber > eb.subscriptionNumber;

    if (isAfter) {
      return 0;
    } else if (!isAfter) {
      return -1;
    } else {
      return 0;
    }
  };

  if (filtredRows.length > 0) {
    switch (reportType) {
      case REPORT_TYPES.VOTANTS:
        render.push(<VotantsTable rows={filtredRows} key="votants-table" />);

        break;
      case REPORT_TYPES.NO_VOTANTS:
        render.push(
          <NoVotantsTable rows={filtredRows} key="novotants-table" />
        );

        break;
      case REPORT_TYPES.ELECTION_RESULTS:
        render.push(<ResultTable rows={filtredRows} key="result-table" />);

        break;
      case REPORT_TYPES.CANDIDATES:
        render.push(
          <CandidatesTable rows={filtredRows} key="candidates-table" sort={sortCandidates}/>
        );

        break;
      case REPORT_TYPES.EMPLOYEES:
        render.push(
          <EmployeesTable rows={filtredRows} key="employees-table" />
        );

        break;

      default:
        break;
    }
  }

  if (filtredRows.length === 0) {
    render.push(
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        key="not-found"
      >
        <Typography variant="body1">Não há dados para exiber</Typography>
      </Box>
    );
  }

  return <Box mt={1}>{render}</Box>;
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    mappedAreas: state.company.areas,
    isLoading: state.company.isLoading,
    errorMessage: state.company.errorMessage,
  };
};

export default connect(mapStateToProps)(ElectionReportsModal);
