import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";

import SelectBox from "../../SelectBox";
import TextInput from "../../TextInput/TextInput";

const ReportFilter = ({
  onChange,
  options,
  reportTypeSelectedValue = null,
  autoSearch = false,
}) => {
  const [filter, setFilter] = useState({});

  const onFilterChangeHandler = (id, value) => {
    const newFilter = filter;
    newFilter[id] = value;

    if (autoSearch) {
      onChange(newFilter);
    } else {
      setFilter(newFilter);
    }
  };

  return (
    <>
      <Box display={"flex"} flexWrap="wrap">
        <Box flexGrow={1} pr={2} maxWidth={"350px"}>
          <TextInput
            fullWidth
            label="Pesquisar"
            onChange={(value) => value.length  > 3 || value.trim() === "" ? onFilterChangeHandler("search_term", value) : null}
          />
        </Box>
        <Box flexGrow={1} maxWidth={"350px"}>
          <SelectBox
            fullWidth
            label="Tipo de relatório"
            selectedValue={reportTypeSelectedValue}
            disableSelect
            onChange={(ev) =>
              onFilterChangeHandler("report_type", ev.target.value)
            }
            options={options}
          />
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          flexGrow={1}
        >
          {!autoSearch ? (
            <Box px={2}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onChange(filter)}
              >
                Buscar
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default ReportFilter;
