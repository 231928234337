import React from "react";
import { makeStyles } from "@material-ui/core";

import CompanyCard from "./CompanyCard/CompanyCard";

const useStyles = makeStyles((theme) => ({
  List: {
    listStyleType: "none",
    display: "flex",
    maxHeight: "700px",
    overflow: "auto",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
  },

  ListItem: {
    flexGrow: 0,
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CompanyCards = (props) => {
  const classes = useStyles();

  const cards = props.companies.map((company, cKey) => {
    const totalEmployees = company.employees ? company.employees.length : 0;

    return (
      <li key={cKey} className={classes.ListItem}>
        <CompanyCard
          logoUrl={company.logoUrl}
          companyId={company.companyId}
          totalEmployes={totalEmployees}
          surname={company.surname}
          name={company.name}
          buttonClicked={() => props.clicked(company)}
          deleteClicked={() => props.deleteClicked(company)}
        />
      </li>
    );
  });

  return <ul className={classes.List}>{cards}</ul>;
};

export default CompanyCards;
