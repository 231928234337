import React, { useEffect, useState } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Button } from "@material-ui/core";
import ReportStyle from "./ReportStyle";

import * as reportColumns from "../columns";

const ReportComponent = ({ reportConfig }) => {
  const {
    imageUrl,
    reportType,
    reportName,
    companyCNPJ,
    electionPeriod,
    rows,
  } = reportConfig;
  
  /*reportColumns.ELECTION_RESULTS[0].format = (row) => (
    <Image
      source={row.photoUrl || "/images/no-photo.jpg"}
      style={{ width: 35, height: 35 }}
    />
  );*/

  return (
    <ReportStyle
      imageUrl={imageUrl}
      columns={reportColumns[reportType]}
      reportName={reportName}
      companyCNPJ={companyCNPJ}
      electionPeriod={electionPeriod}
      rows={rows}
    />
  );
};

export const DownloadComponent = ({ reportConfig }) => {
  const reportNameEnum = {};

  const downloadHandler = (filePath, fileName) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.download = fileName;
    link.target = "_blank";
    link.click();
  };

  reportNameEnum[reportConfig.REPORT_TYPES.VOTANTS] =
    "Lista de Funcionários que Votaram";
  reportNameEnum[reportConfig.REPORT_TYPES.NO_VOTANTS] =
    "Lista de Funcionários que Não Votaram";
  reportNameEnum[reportConfig.REPORT_TYPES.ELECTION_RESULTS] =
    "Resultado da Eleição";
  reportNameEnum[reportConfig.REPORT_TYPES.CANDIDATES] = "Lista de Candidatos";
  reportNameEnum[reportConfig.REPORT_TYPES.EMPLOYEES] = "Lista de Funcionarios";

  reportConfig.reportName = reportNameEnum[reportConfig.reportType];

  const [instance] = usePDF({
    document: <ReportComponent reportConfig={reportConfig} />,
  });

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      reportConfig.rows &&
      reportConfig.rows.length > 0
    ) {
      downloadHandler(
        instance.url,
        reportConfig.reportName + " " + reportConfig.electionName + ".pdf"
      );
    }
  }, [instance, reportConfig]);

  return null;
};

export const PrintButton = ({ reportConfig }) => {
  const [download, initDownload] = useState(false);

  const handlerDownload = () => {
    initDownload(true);

    window.setTimeout(() => {
      initDownload(false);
    }, 2000);
  };

  return (
    <>
      {download ? <DownloadComponent reportConfig={reportConfig} /> : null}
      <Button
        color="primary"
        variant="contained"
        onClick={() => handlerDownload()}
      >
        Baixar PDF
      </Button>
    </>
  );
};
