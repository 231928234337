import React from "react";

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  image: {
    width: 50,
    height: 50,
  },
});

const VoucherStyleRows = ({ label, value }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        border: "1px solid #ccc",
        borderBottom: "inherit",
      }}
    >
      <Text
        style={{
          width: "50%",
          textAlign: "left",
          fontSize: "8px",
          borderBottom: "1px solid #ccc",
          borderRight: "1px solid #ccc",
          padding: "6px",
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          width: "50%",
          textAlign: "left",
          fontSize: "8px",
          borderBottom: "1px solid #ccc",
          fontWeight: "bold",
          padding: "6px",
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const VoucherStyle = ({
  mqvProLogo,
  imageUrl,
  electionName,
  companyName,
  companyCNPJ,
  subscribeNumber,
  employeeName,
  employeeRegister,
  subscribeDate,
  subscribeTime,
}) => {
  return (
    <Document style={{ width: window.innerWidth, height: window.innerHeight }}>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: "70%",
            margin: "0 auto",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <View>
              <Image
                style={{ width: 35 }}
                source={{
                  uri: imageUrl,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                }}
              />
            </View>
            <View style={{ marginTop: "12px" }}>
              <Text style={{ textAlign: "left" }}>{electionName}</Text>
            </View>
            <View style={{ marginTop: "8px" }}>
              <Text style={{ textAlign: "left", fontSize: "12px" }}>
                COMPROVANTE DE INSCRIÇÃO
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              marginTop: "8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VoucherStyleRows label={"Razão Social: "} value={companyName} />
            <VoucherStyleRows label={"CNPJ: "} value={companyCNPJ} />
            <VoucherStyleRows label={"Funcionário"} value={employeeName} />
            <VoucherStyleRows label={"Matrícula"} value={employeeRegister} />
            <VoucherStyleRows label={"Data Inscrição"} value={subscribeDate} />
            <VoucherStyleRows
              label={"Horário da Inscrição"}
              value={subscribeTime}
            />
          </View>
          <View
            style={{
              marginTop: "8px",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <Image
              style={{ width: 50 }}
              source={{
                uri: mqvProLogo,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default VoucherStyle;
