import React from "react";
import TextInput from "../TextInput/TextInput";

export default function DatePicker({
  label,
  value,
  formik,
  fieldId,
}) {
  const currentDate = value || Date.now();

  return (
    <TextInput
      label={label}
      value={currentDate}
      fieldId={fieldId}
      formik={formik}
      type="date"
    />
  );
}
