import React from "react";

import Modal from "../Modal/Modal";

//import CompanyForm from "../../CompanyDashboard/CompanyForm/CompanyForm";
import { Box } from "@material-ui/core";

const MqvModal = ({
  closed,
  title,
  maxWidth = "400",
  zIndex = 1,
  component,
}) => {
  return (
    <Modal
      isOpen
      closed={closed}
      maxWidth={component.props.maxWidth || maxWidth}
      title={title}
      zIndex={zIndex}
      {...component.props}
    >
      <Box>{component}</Box>
    </Modal>
  );
};

export default MqvModal;
