import React from "react";

import ElectionCard from "./ElectionCard/ElectionCard";

const ElectionCards = ({ elections, setCompany }) => {
  return elections.map((election, elKey) => (
    <ElectionCard
      logoUrl={election.logoUrl}
      status={election.status}
      statusName={election.statusName}
      companyName={election.companyName}
      electionName={election.name}
      clicked={() => setCompany(election.company)}
      key={elKey}
    />
  ));
};

export default ElectionCards;
