import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const UploadButton = ({ onChange, children, accept }) => {
  const classes = useStyles();

  return (
    <>
      <input
        className={classes.input}
        id="contained-button-file"
        accept={accept}
        onChange={onChange}
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {children}
        </Button>
      </label>
    </>
  );
};

export default UploadButton;
