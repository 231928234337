import React from "react";

import { makeStyles, Box, IconButton, Typography } from "@material-ui/core";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import { ELECTION_STATES_COLORS } from "enums/electionStates";

const getLogoUrl = (logoUrl) => {
  const url = `${logoUrl}`;
  return url;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "60px",
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 2px 5px rgb(0 0 0 / 0.25)",
  },
  Logo: {
    width: 60,
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  Infos: {
    width: 80,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(10),
  },
  Status: {
    width: "10px",
    height: "100%",
    borderRadius: 8,
  },
}));

const ElectionCard = ({ status, logoUrl, statusName, companyName, electionName, clicked }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      className={classes.root}
      mb={3}
      p={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        className={classes.Logo}
        title={companyName}
        width={50}
        style={{
          backgroundImage: `url('${getLogoUrl(logoUrl)}')`,
        }}
      ></Box>
      <Box width={100} textAlign="left" pl={1}>
        <Typography variant="caption" >
          <strong>{electionName}</strong>
        </Typography>
      </Box>
      <Box>
        <IconButton
          color="secondary"
          variant="contained"
          aria-label="add an alarm"
          title="Administrar"
          onClick={clicked}
        >
          <BuildRoundedIcon color="primary" />
        </IconButton>
      </Box>

      <Box
        className={classes.Status}
        title={"Status da eleição: " + statusName}
        style={{ backgroundColor: ELECTION_STATES_COLORS[status] }}
      ></Box>
    </Box>
  );
};

export default ElectionCard;
