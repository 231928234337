export const ELECTION_STATES = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  CANCELED: 2,
  PENDING: 3,
  FINISHED: 4,
  VERIFICATED: 5,
  SUBSCRIPTION: 6,
  FILLING: 7,
};

export const ELECTION_FOLLOW_STATES = [ELECTION_STATES.NOT_STARTED, ELECTION_STATES.IN_PROGRESS, ELECTION_STATES.PENDING, ELECTION_STATES.SUBSCRIPTION, ELECTION_STATES.FINISHED, ELECTION_STATES.VERIFICATED]

export const ELECTION_STATES_NAMES = (status) =>
  ({
    [ELECTION_STATES.NOT_STARTED]: "Aguardando início",
    [ELECTION_STATES.IN_PROGRESS]: "Em andamento",
    [ELECTION_STATES.CANCELED]: "Cancelada",
    [ELECTION_STATES.PENDING]: "Pendente",
    [ELECTION_STATES.FINISHED]: "Encerrada aguardando apuração",
    [ELECTION_STATES.VERIFICATED]: "Apurada",
    [ELECTION_STATES.SUBSCRIPTION]: "Aguardando inscrições dos candidatos",
    [ELECTION_STATES.FILLING]: "Em preenchimento",
  }[status]);

export const ELECTION_STATES_COLORS = {
  [ELECTION_STATES.IN_PROGRESS]: "#64FF61",
  [ELECTION_STATES.NOT_STARTED]: "#FAC2D5",
  [ELECTION_STATES.CANCELED]: "#D7D7D7",
  [ELECTION_STATES.PENDING]: "#e44b4b",
  [ELECTION_STATES.FINISHED]: "#292929",
  [ELECTION_STATES.VERIFICATED]: "#7B61FF",
  [ELECTION_STATES.SUBSCRIPTION]: "#9bc2e0",
  [ELECTION_STATES.FILLING]: "#CCCCCC",
};
