import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";
import LinearProgressWithLabel from "../LinerProgressWithLabel/LinerProgressWithLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    flexDirection: "column",
  },
  label: {
    flexGrow: 1,
  },
  linearProgress: {
    flexGrow: 1,
    width: '100%',
  }
}));

const PercentBox = ({ label, progress, suficient }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Box className={classes.label}>
          <Typography variant="body1">
            {label}
          </Typography>
        </Box>
        <Box className={classes.linearProgress}>
          <LinearProgressWithLabel value={progress} danger={!suficient} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PercentBox;
