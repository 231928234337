import {
  Box,
  Button,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {},
  item: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: "250px",
    border: "1px solid #e2e2e2",
    boxShadow: "5px 5px 5px #e2e2e2",
    borderRadius: "5px",
  },
  photo: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "100%",
    width: "150px",
    height: "150px",
    margin: "0 auto",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  labels: {
    textAlign: "center",
  },
}));

const CandidateList = ({
  list,
  isAlphabetical = false,
  isShowSurname = false,
  onVote,
}) => {
  let render = [];
  const classes = useStyle();

  render = isAlphabetical
    ? list.sort((a, b) => a.name.localeCompare(b.name))
    : list;

  return (
    <Box
      display="flex"
      flexGrow={0}
      p={1}
      className={classes.root}
      justifyContent="space-evenly"
      flexWrap="wrap"
    >
      {render.map((candidate, key) => {
        return (
          <Box key={key} className={classes.item}>
            <Box className={classes.photo}>
              <img
                src={candidate.photoUrl || "/images/no-photo.jpg"}
                alt="Foto do Candidato"
                width="100%"
              />
            </Box>
            <Box
              my={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="subtitle1" align="center">
                <strong className="classes.labels">{candidate.name}</strong>
              </Typography>
              {isShowSurname ? (
                <Typography variant="subtitle2" className="classes.labels" align="center">
                  {candidate.surname}
                </Typography>
              ) : null}
              <Typography variant="caption" className="classes.labels" align="center">
                {candidate.department}
              </Typography>
              <Box my={2} width={"100%"}>
                <Divider />
              </Box>
              <Typography variant="caption" align="center">{candidate.areaName}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignContent="center">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => onVote(candidate)}
              >
                Votar
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CandidateList;
