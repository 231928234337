import { combineReducers } from "redux";

import companyReducer from "./companyReducer";
import notificationReducer from "./notificationReducer";
import uiReducer from "./uiReducer";

const rootReducer = combineReducers({
  company: companyReducer,
  ui: uiReducer,
  notification: notificationReducer,
});

export default rootReducer;
