import {
  Box,
  Button,
  TextField,
  makeStyles,
  colors,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import ErrorMessage from "../../UI/ErrorMessage/ErrorMessage";

const validationSchema = yup.object({
  fldusername: yup.string().required("Requerido"),
  fldpassword: yup.string().required("Requerido"),
});

const styles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginBox: {
    background: colors.grey[50],
  },
  title: {
    fontWeight: "bold",
  },
}));

const ElectionLogin = ({
  electionName,
  logoUrl,
  onLogin,
  isErrorMessage = null,
}) => {
  const classes = styles();
  const formik = useFormik({
    initialValues: {
      fldusername: "",
      fldpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      onLogin(data.fldusername, data.fldpassword);
    },
  });

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        boxShadow={3}
        px={3}
        borderRadius={8}
        maxWidth="350px"
        className={classes.loginBox}
      >
        <Box textAlign="center" mt={3}>
          <img src="/logo-mqv-pro.png" alt="logo" />
        </Box>
        
        <Box my={1}>
          <Divider />
        </Box>

        <Box textAlign="center" mt={3}>
          <Typography variant="subtitle2">
            {"Eleição - " + electionName}
            <br></br>
            <img src={logoUrl} alt="logo" width={"80px"} />
          </Typography>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Box my={1}>
            <TextField
              id="fldusername"
              type="text"
              label="Usuário"
              variant="outlined"
              fullWidth
              value={formik.values.fldusername}
              onChange={formik.handleChange}
              error={formik.touched.fldusername && Boolean(formik.errors.fldusername)}
              helperText={formik.touched.fldusername && formik.errors.fldusername}
              margin="normal"
            />
            <TextField
              id="fldpassword"
              type="fldpassword"
              label="Senha"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formik.values.fldpassword}
              onChange={formik.handleChange}
              error={formik.touched.fldpassword && Boolean(formik.errors.fldpassword)}
              helperText={formik.touched.fldpassword && formik.errors.fldpassword}
            />
          </Box>

          {isErrorMessage ? (
            <ErrorMessage severity="error">{isErrorMessage}</ErrorMessage>
          ) : null}

          <Box my={5}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Entrar
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ElectionLogin;
