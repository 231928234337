import React, { useCallback, useEffect, useState } from "react";

import { PDFViewer, usePDF } from "@react-pdf/renderer";
import VoucherStyle from "./VoucherStyle";

export const PDFDocument = ({ reportConfig }) => {
  const {
    mqvProLogo,
    imageUrl,
    electionName,
    companyName,
    companyCNPJ,
    subscribeNumber,
    employeeName,
    employeeRegister,
    subscribeDate,
    subscribeTime,
  } = reportConfig;

  return (
    <PDFViewer>
      <VoucherStyle
        mqvProLogo={mqvProLogo}
        imageUrl={imageUrl}
        electionName={electionName}
        companyName={companyName}
        companyCNPJ={companyCNPJ}
        subscribeNumber={subscribeNumber}
        employeeName={employeeName}
        employeeRegister={employeeRegister}
        subscribeDate={subscribeDate}
        subscribeTime={subscribeTime}
      />
    </PDFViewer>
  );
};

const ReportComponent = ({ reportConfig }) => {
  const {
    mqvProLogo,
    imageUrl,
    electionName,
    companyName,
    companyCNPJ,
    subscribeNumber,
    employeeName,
    employeeRegister,
    subscribeDate,
    subscribeTime,
  } = reportConfig;

  return (
    <VoucherStyle
      mqvProLogo={mqvProLogo}
      imageUrl={imageUrl}
      electionName={electionName}
      companyName={companyName}
      companyCNPJ={companyCNPJ}
      subscribeNumber={subscribeNumber}
      employeeName={employeeName}
      employeeRegister={employeeRegister}
      subscribeDate={subscribeDate}
      subscribeTime={subscribeTime}
    />
  );
};

export const DownloadComponent = ({ reportConfig }) => {
  const [instance] = usePDF({
    document: <ReportComponent reportConfig={reportConfig} />,
  });

  const manageInstance = useCallback(() => {
    const link = document.createElement("a");
    const { electionName, employeeName } = reportConfig;
    const { url } = instance;

    link.download =
      "comprovante_inscricao_" +
      electionName +
      " " +
      employeeName +
      "" +
      ".pdf";
    link.href = url;
    link.click();
  }, [instance, reportConfig]);

  useEffect(() => {
    if (!instance.loading && instance.url) {
      manageInstance();
    }
  }, [instance, manageInstance]);

  return null;
};

export const EnrollmentVoucher = ({ reportConfig, Component }) => {
  const [download, initDownload] = useState(false);

  const handlerDownload = () => {
    initDownload(true);

    window.setTimeout(() => {
      initDownload(false);
    }, 2000);
  };

  return (
    <>
      {download ? <DownloadComponent reportConfig={reportConfig} /> : null}
      <Component handlerDownload={handlerDownload} />
    </>
  );
};
