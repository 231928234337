export const JSONToCSVConvertor = (
  JSONData,
  ReportTitle,
  columns,
  ShowLabel
) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";

  const columnEnum = {};
  const columnOriginal = {};

  columns.forEach((column) => {
    if (!column.ignoreInReports) {
      columnEnum[column.id] = column.label;
      columnOriginal[column.id] = column;
    }
  });

  console.log(columnOriginal);

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var labelRow = "";

    //This loop will extract the label from 1st index of on array
    for (const index in columnOriginal) {
      //Now convert each value to string and comma-seprated
      const columnLabel = columnOriginal[index].label;
      if (columnLabel) {
        labelRow += columnLabel + ",";
      }
    }

    labelRow = labelRow.slice(0, -1);

    //append Label row with line break
    CSV += labelRow + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (const index in columnOriginal) {
      const column = columnOriginal[index];

      let value = arrData[i][index] || "Não possúi";

      if (column.format) {
        value = column.format(arrData[i], true);
      }

      row += '"' + value + '",';
    }

    row = row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(CSV);
  var link = document.createElement("a");
  link.href = uri;
  link.download = fileName + ".csv";
  link.click();
};
