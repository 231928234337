import React from "react";

import { Typography, Grid, makeStyles, Paper } from "@material-ui/core";

import Companies from "./Companies/Companies";
import Elections from "./Elections/Elections";

const useStyles = makeStyles((theme) => ({
  Elections: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    maxWidth: 280
  },
  Companies: {
    padding: theme.spacing(3),
  },
  Paper: {
    marginTop: theme.spacing(4),
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h1">Dashboard</Typography>
      <Paper className={classes.Paper}>
        <Grid container>
          <Grid item sm className={classes.Elections}>
            <Elections />
          </Grid>
          <Grid item sm className={classes.Companies}>
            <Companies />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default Dashboard;
