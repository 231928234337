import { API, Storage } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";

import { ELECTION_STATES } from "enums/electionStates";

export async function getById(companyId) {
  const company = await API.get("company-api", "/company/get/" + companyId);
  company.electionStates = ELECTION_STATES;

  return company;
}

export async function getAll() {
  const companies = await API.get("company-api", "/company/list");

  return companies;
}

export async function create(requestBody) {
  requestBody.electionStates = ELECTION_STATES;

  const createdCompany = await API.post("company-api", "/company/create", {
    body: { ...requestBody },
  });

  return createdCompany.body;
}

export async function updateById(companyId, requestBody) {
  requestBody.electionStates = ELECTION_STATES;

  return await API.put("company-api", "/company/update/" + companyId, {
    body: requestBody,
  });
}

export async function deleteById(companyId) {
  const response = await API.del("company-api", "/company/delete/" + companyId);
  return response.status;
}

export async function updateLogoUrl(companyId, logoUrl) {
  return await API.put("company-api", "/company/update_logo/" + companyId, {
    body: {
      logoUrl: logoUrl,
    },
  });
}

export async function uploadLogo(file) {
  const logoUrl = file ? await s3Upload(file, "logo") : null;

  return logoUrl;
}

export async function uploadPhoto(file, companyId) {
  const logoUrl = file ? await s3Upload(file, "photos/" + companyId) : null;

  return logoUrl;
}

export async function deleteLogo(logoUrl) {
  await Storage.vault.remove(logoUrl);
}

export async function updateElection(companyId, requestBody) {
  return await API.put(
    "company-api",
    "/company/" + companyId + "/update/election",
    {
      body: requestBody,
    }
  );
}

export async function getEmployee(companyId, requestBody, areaId) {
  return await API.get(
    "company-api",
    "/company/" + companyId + "/get/employee/area/" + areaId,
    {
      body: requestBody,
    }
  );
}

export async function createEmployees(companyId, requestBody, areaId) {
  return await API.post(
    "company-api",
    "/company/" + companyId + "/create/employees/area/" + areaId,
    {
      body: requestBody,
    }
  );
}

export async function updateEmployee(companyId, requestBody) {
  return await API.put(
    "company-api",
    "/company/" + companyId + "/update/election/employee",
    {
      body: requestBody,
    }
  );
}

export async function deleteEmployee(companyId, requestBody, isAll) {
  return await API.del(
    "company-api",
    "/company/" + companyId + "/employee" + (isAll ? "/all" : ""),
    {
      body: requestBody,
    }
  );
}

export async function getElectionResult(companyId, requestBody) {
  return await API.get(
    "company-api",
    "/company/" + companyId + "/get/election/result",
    {}
  );
}

export async function sendEmails(emails) {
  return await API.post("company-api", "/send/email", {
    body: {
      message: "E-mail de teste vindo do Client",
      subject: "Inscrição iniciada!",
      emails: emails,
    },
  });
}

export async function updateElectionStatus(companyId) {
  return await API.get(
    "company-api",
    "/company/" + companyId + "/update/election/status",
    {}
  );
}

export async function importEmployees(data, companyId) {
  return await API.post("company-api", "/company/" + companyId + "/import/employees", {
    body: data,
  });
}