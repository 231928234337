import React, { useState } from "react";

import { connect } from "react-redux";

import { Box, Divider, Typography } from "@material-ui/core";

import * as companyActions from "../../../../../store/actions/companyActions";
import CandidateList from "../CandidateList";
import Options from "../Options";
import EmployeesFilter from "../../ElectionCandidatesModal/CandidateList/EmployeesFilter";

const optionsProperties = {
  IS_ALPHABETIC: "isAlphabeticCandidateListOrder",
  IS_SHOWNAME: "isShowSurnameCandidateList",
};

const PreviewCandidate = ({ dispatch, company, managerAccess }) => {
  const { election } = company;
  const [filteredCandidates, setFilteredCandidates] = useState();
  const candidates = company.election.areas.reduce((oldArea, area) => {
    area.employees.map((employee) => {
      employee.areaName = area.areaName;
      if (employee.isCandidate && employee.isSubscribeApproved) {
        oldArea.push(employee);
      }
      return employee;
    });

    return oldArea;
  }, []);

  const [isAlphabetic, setIsAlphabetic] = useState(
    election[optionsProperties.IS_ALPHABETIC]
  );
  const [isShowSurname, setIsShowSurname] = useState(
    election[optionsProperties.IS_SHOWNAME]
  );

  const setElectionConfigs = (newValue, property) => {
    const params = {};

    params[property] = newValue;

    dispatch(companyActions.saveElectionOptions(company.companyId, params));

    if (property === optionsProperties.IS_ALPHABETIC) {
      setIsAlphabetic(newValue);
    } else {
      setIsShowSurname(newValue);
    }
  };

  const setFilteredEmployees = (newCandidates) => {
    setFilteredCandidates(newCandidates);
  };

  return (
    <Box>
      {!managerAccess ? (
        <>
          <Box>
            <Options
              isAlphabetic={isAlphabetic}
              isShowSurname={isShowSurname}
              setAlphabetic={(vl) =>
                setElectionConfigs(vl, optionsProperties.IS_ALPHABETIC)
              }
              setShowSurname={(vl) =>
                setElectionConfigs(vl, optionsProperties.IS_SHOWNAME)
              }
            />
          </Box>

          <Divider />
        </>
      ) : null}

      <Box display="flex" justifyContent="flex-end">
        <Box flexGrow={1} maxWidth="400px">
          <EmployeesFilter
            employeesList={candidates}
            hideNameInput
            areasList={company.election.areas}
            onResult={(list) => {
              setFilteredEmployees(list);
            }}
          />
        </Box>
      </Box>

      <Divider />

      <Box py={2}>
        {candidates.length > 0 ? (
          <CandidateList
            isAlphabetical={isAlphabetic}
            isShowSurname={isShowSurname}
            onVote={() => {}}
            list={filteredCandidates || candidates}
          />
        ) : (
          <Box>
            <Typography>Não existem candidatos a serem exibidos.</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  managerAccess: state.company.managerAccess,
});

export default connect(mapStateToProps)(PreviewCandidate);
