import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";

import { useParams } from "react-router-dom";

import { useAppContext } from "libs/contextLib";

import { Typography, Box } from "@material-ui/core";

import * as companyActions from "../../store/actions/companyActions";

import b64 from "base-64";
import Votation from "./Votation";
import ElectionLogin from "./ElectionLogin";
import {
  getUTCDate,
  getUTCDateObj,
  getUTCNow,
  isInPeriod,
} from "components/UI/utils";

import { getGeolocation } from "services/geolocation";
import Inscription from "./Inscription";
import Manager from "./Manager";

const Election = ({ dispatch, company, hasErrors }) => {
  const params = useParams();
  const [accessType, setAccessType] = useState(null);
  const [section, setSection] = useState("login");
  const [election, setElection] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { socket } = useAppContext();

  const isElectionPeriod = useCallback(() => {
    const electionPeriodStart = getUTCDateObj(
      company.election.electionDateStart,
      company.election.electionTimeStart
    );

    const electionPeriodEnd = getUTCDateObj(
      company.election.electionDateEnd,
      company.election.electionTimeEnd
    );

    const dateNow = getUTCNow();
    const electionPeriod = isInPeriod(
      electionPeriodStart,
      electionPeriodEnd,
      dateNow
    );

    return (
      electionPeriod &&
      company.election.status === company.electionStates.IN_PROGRESS
    );
  }, [company]);

  const isInscriptionPeriod = useCallback(() => {
    const inscriptionPeriodStart = getUTCDateObj(
      company.election.subscribePeriodDateStart,
      company.election.subscribePeriodTimeStart
    );

    const inscriptionPeriodEnd = getUTCDateObj(
      company.election.subscribePeriodDateEnd,
      company.election.subscribePeriodTimeEnd
    );

    const dateNow = getUTCNow();
    const inscriptionPeriod = isInPeriod(
      inscriptionPeriodStart,
      inscriptionPeriodEnd,
      dateNow
    );

    return (
      inscriptionPeriod &&
      company.election.status === company.electionStates.SUBSCRIPTION
    );
  }, [company]);

  const getCompanyHandler = useCallback(() => {
    if (params.code) {
      const electionParams = b64.decode(params.code).split("+");
      const electionData = {
        type: electionParams[0],
        companyId: electionParams[1],
      };

      setAccessType(electionData.type);

      dispatch(companyActions.fetchCompanyById(electionData.companyId));
    }
  }, [dispatch, params.code]);

  useEffect(() => {
    getCompanyHandler();
  }, [getCompanyHandler]);

  const onSubscribeHandler = async () => {
    const subscribeDateArr = getUTCDate(new Date(), true, true).split(" ");
    const subscribeDate = subscribeDateArr[0];
    const subscribeTime = subscribeDateArr[1];

    election.employee.isCandidate = true;
    election.employee.subscribeDate = subscribeDate;
    election.employee.subscribeTime = subscribeTime;
    election.employee.subscriptionNumber = getUTCDateObj(subscribeDate, subscribeTime).unix();

    dispatch(
      companyActions.updateEmployee(company.companyId, election.employee)
    );
  };

  const onVoteHandler = async (vote) => {
    const geolocation = await getGeolocation();

    election.employee.votedIn = vote;
    election.employee.votedInTime = getUTCDate(new Date(), true);
    election.employee.votedInIp = geolocation.data.IPv4;

    socket?.send(
      JSON.stringify({
        type: "NEW_VOTE",
        action: "message",
        data: {
          message: "Um voto foi computado com sucesso !",
          subscribeNumber: election.employee.subscribeNumber,
          votedIn: election.employee.votedIn,
          companyId: company.companyId,
          areaId: election.employee.areaId,
          createdAt: new Date(),
          votedInTime: election.employee.votedInTime,
          votedInIp: geolocation.IPv4,
        },
      })
    );

    dispatch(
      companyActions.updateEmployee(company.companyId, election.employee)
    );
  };

  const onLoginHandler = (username, password) => {
    let votedIn = false;

    const found = company.election.areas.some((area) => {
      let loggedEmployee = null;

      const found = area.employees.some((employee) => {
        if (employee.username === username && employee.password === password) {
          loggedEmployee = employee;
          votedIn = loggedEmployee.votedIn;
          return true;
        }

        return false;
      });

      if (found) {
        setElection({
          area: area,
          employee: loggedEmployee,
        });
        return true;
      }

      return false;
    });

    if (!found) {
      return setErrorMessage("Usuário não encontrado !");
    }

    if (isInscriptionPeriod()) {
      setSection("subscribePeriod");
    }

    if (company.election.status === company.electionStates.PENDING) {
      return setErrorMessage("A Eleição está em espera!");
    }

    if (company.election.status === company.electionStates.CANCELED) {
      return setErrorMessage("A Eleição foi cancelada!");
    }

    if (
      !votedIn &&
      company.election.status === company.electionStates.FINISHED
    ) {
      return setErrorMessage("A Eleição já se encerrou!");
    }

    if (isElectionPeriod()) {
      setSection("vote");
    } else {
      return setErrorMessage("A Eleição não começou ou está fora do periodo!");
    }
  };

  let render = null;

  if (company) {
    if (section === "subscribePeriod") {
      render = (
        <Inscription
          employee={election.employee}
          area={election.area}
          onSubscribe={onSubscribeHandler}
        />
      );
    } else if (section === "vote") {
      render = (
        <Votation
          onVote={onVoteHandler}
          company={company}
          hasErrors={hasErrors}
          employee={election.employee}
          area={election.area}
        />
      );
    } else if (accessType === "employee") {
      render = (
        <ElectionLogin
          electionName={company.election.name}
          logoUrl={company.logoUrl}
          onLogin={onLoginHandler}
          isErrorMessage={errorMessage}
        />
      );
    } else if (accessType === "manager") {
      render = <Manager company={company} />;
    } else {
      render = (
        <Box>
          <Typography variant="body1">
            Eleição não encontrada!, contate o administrador
          </Typography>
        </Box>
      );
    }
  }

  return <React.Fragment>{render}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  company: state.company.company,
  isLoading: state.company.isLoading,
  hasErrors: state.company.hasErrors,
  notification: state.company.notification,
});

export default connect(mapStateToProps)(Election);
