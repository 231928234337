import React from "react";

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { getUTCDate } from "components/UI/utils";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  image: {
    width: 50,
    height: 50,
  },
});

const ReportStyleColumns = ({ columns }) => {
  return columns.map((column, index) => (
    <Text
      key={index}
      style={{
        flex: 1,
        textAlign: "center",
        fontSize: "8px",
        flexGrow: 1,
      }}
    >
      {column.label}
    </Text>
  ));
};

const ReportStyleRows = ({ columns, rows }) => {
  return rows.map((row, index) => {
    return (
      <View
        key={index}
        style={{
          flexDirection: "row",
          marginTop: "12px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {columns.map((column, cindex) => {
          return (
            <Text
              key={cindex}
              style={{
                flex: 1,
                textAlign: "center",
                fontSize: "6px",
                flexGrow: 1,
              }}
            >
              {column.format ? column.format(row, true) : row[column.id]}
            </Text>
          );
        })}
      </View>
    );
  });
};

const ReportStyle = ({
  imageUrl,
  rows,
  companyCNPJ,
  electionPeriod,
  reportName,
  columns,
}) => {
  const filtredColumns = columns.filter(c => !c.ignoreInReports);
  return (
    <Document style={{ width: window.innerWidth, height: window.innerHeight }}>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "6px",
            marginBottom: "20px",
          }}
        >
          <Text>Relatório gerado em: {getUTCDate(new Date(), true)}</Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <Text style={{ textAlign: "center", width: "100%" }}>
            {reportName}
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              width: 100,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <View>
              <Image
                style={{ width: 35 }}
                source={{
                  uri: imageUrl,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                }}
              />
            </View>
            <View style={{ marginTop: "8px" }}>
              <Text style={{ textAlign: "left", fontSize: "8px" }}>
                CNPJ: {companyCNPJ}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <Text style={{ textAlign: "center", width: "100%", fontSize: "8px" }}>
            {"Período de " + electionPeriod}
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            fontStyle: "bold",
            borderTop: "3px solid #ccc",
            borderBottomWidth: 1,
            borderBottomColor: "#bff0fd",
            alignItems: "center",
            textAlign: "center",
            marginTop: "2px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <ReportStyleColumns columns={filtredColumns} />
        </View>
        <ReportStyleRows columns={filtredColumns} rows={rows} />
      </Page>
    </Document>
  );
};

export default ReportStyle;
