import {
  Toolbar,
  CssBaseline,
  makeStyles,
  Box,
} from "@material-ui/core";
import React from "react";
import AppBar from "../AppBar/AppBar";
import MqvModal from "../../UI/MqvModal/MqvModal";
import { connect } from "react-redux";

import PreviewCandidate from "../../CompanyDashboard/Election/ElectionCandidates/PreviewCandidate";

import * as uiActions from "../../../store/actions/uiActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: "0 " + theme.spacing(3) + "px",
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  notifications: {
    backgroundColor: theme.palette.grey[100],
    width: 280,
    padding: theme.spacing(3),
    boxShadow: "-1px 1px 5px " + theme.palette.grey[400],
    flexGrow: 1,
    maxWidth: 280,
  },
}));

const PrivateLayout = ({
  children,
  mqvModalOpen,
  mqvModalTitle,
  mqvModalChildren,
  mqvModalSize,
  candidatePreviewModalOpen,
  dispatch,
}) => {
  const classes = useStyles();

  const closeMqvModal = () => {
    dispatch(uiActions.closeMqvModal());
  };

  const closeCandidatePreviewModal = () => {
    dispatch(uiActions.showCandidatePreviewModal(false));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar logoutClicked={() => window.location.reload()} />

        {mqvModalOpen ? (
          <MqvModal
            title={mqvModalTitle}
            component={mqvModalChildren}
            closed={() => closeMqvModal()}
            maxWidth={mqvModalSize}
          />
        ) : null}

        {candidatePreviewModalOpen ? (
          <MqvModal
            title="Prévia Candidatos"
            component={<PreviewCandidate />}
            closed={() => closeCandidatePreviewModal()}
            maxWidth="900"
            zIndex={2}
          />
        ) : null}

        <Box display="flex" flexGrow={1}>
          <main className={classes.content}>
            <Toolbar />
            {children}
          </main>
        </Box>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  mqvModalOpen: state.ui.mqvModalOpen,
  mqvModalTitle: state.ui.mqvModalTitle,
  mqvModalChildren: state.ui.mqvModalChildren,
  mqvModalSize: state.ui.mqvModalSize,
  candidatePreviewModalOpen: state.ui.candidatePreviewModalOpen,
});

export default connect(mapStateToProps)(PrivateLayout);
