import * as companyService from "../../services/company";
import * as operations from "../../store/operations/companyOperations";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export const SET_COMPANY = "SET_COMPANY";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const UPLOAD_COMPANY_LOGO = "UPLOAD_COMPANY_LOGO";
export const UPLOAD_COMPANY_LOGO_SUCCESS = "UPLOAD_COMPANY_LOGO_SUCCESS";
export const UPLOAD_COMPANY_LOGO_ERROR = "UPLOAD_COMPANY_LOGO_ERROR";

export const UPDATE_COMPANY_LOGO_URL = "UPDATE_COMPANY_LOGO_URL";
export const UPDATE_COMPANY_LOGO_URL_SUCCESS =
  "UPDATE_COMPANY_LOGO_URL_SUCCESS";
export const UPDATE_COMPANY_LOGO_URL_ERROR = "UPDATE_COMPANY_LOGO_URL_ERROR";

export const DELETE_COMPANY_LOGO = "DELETE_COMPANY_LOGO";
export const DELETE_COMPANY_LOGO_SUCCESS = "DELETE_COMPANY_LOGO_SUCCESS";
export const DELETE_COMPANY_LOGO_ERROR = "DELETE_COMPANY_LOGO_ERROR";

export const GET_COMPANY_LOGO = "GET_COMPANY_LOGO";

export const REMOVE_MESSAGES = "REMOVE_MESSAGES";

export const SAVE_ELECTION_CONFIG = "SAVE_ELECTION_CONFIG";
export const SAVE_ELECTION_CONFIG_SUCCESS = "SAVE_ELECTION_CONFIG_SUCCESS";
export const SAVE_ELECTION_CONFIG_ERROR = "SAVE_ELECTION_CONFIG_ERROR";

export const SAVE_ELECTION_PERIOD = "SAVE_ELECTION_PERIOD";
export const SAVE_ELECTION_PERIOD_SUCCESS = "SAVE_ELECTION_PERIOD_SUCCESS";
export const SAVE_ELECTION_PERIOD_ERROR = "SAVE_ELECTION_PERIOD_ERROR";

export const SAVE_AREA = "SAVE_AREA";
export const SAVE_AREA_SUCCESS = "SAVE_AREA_SUCCESS";
export const SAVE_AREA_ERROR = "SAVE_AREA_ERROR";

export const SAVE_EMPLOYEES = "SAVE_EMPLOYEES";
export const SAVE_EMPLOYEES_SUCCESS = "SAVE_EMPLOYEES_SUCCESS";
export const SAVE_EMPLOYEES_ERROR = "SAVE_EMPLOYEES_ERROR";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";

export const UPLOAD_EMPLOYEE_PHOTO = "UPDATE_EMPLOYEE_PHOTO";
export const UPLOAD_EMPLOYEE_PHOTO_SUCCESS = "UPDATE_EMPLOYEE_PHOTO_SUCCESS";
export const UPLOAD_EMPLOYEE_PHOTO_ERROR = "UPDATE_EMPLOYEE_PHOTO_ERROR";

export const SET_ELECTION_STATUS = "SET_ELECTION_STATUS";
export const SET_ELECTION_STATUS_SUCCESS = "SET_ELECTION_STATUS_SUCCESS";
export const SET_ELECTION_STATUS_ERROR = "SET_ELECTION_STATUS_ERROR";

export const GET_ELECTION_RESULT = "GET_ELECTION_RESULT";
export const GET_ELECTION_RESULT_SUCCESS = "GET_ELECTION_RESULT_SUCCESS";
export const GET_ELECTION_RESULT_ERROR = "GET_ELECTION_RESULT_ERROR";

export const SAVE_ELECTION = "SAVE_ELECTION";
export const SAVE_ELECTION_SUCCESS = "SAVE_ELECTION_SUCCESS";
export const SAVE_ELECTION_ERROR = "SAVE_ELECTION_ERROR";

export const UPDATE_ELECTION_STATUS = "UPDATE_ELECTION_STATUS";
export const UPDATE_ELECTION_STATUS_SUCCESS = "UPDATE_ELECTION_STATUS_SUCCESS";
export const UPDATE_ELECTION_STATUS_ERROR = "UPDATE_ELECTION_STATUS_ERROR";

export const getCompanies = () => ({
  type: GET_COMPANIES,
});

export const getCompaniesWithSuccess = (companies) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
});

export const getCompaniesWithError = (error) => ({
  type: GET_COMPANIES_ERROR,
  payload: error,
});

export const getCompany = () => ({
  type: GET_COMPANY,
});

export function fetchCompanies() {
  return async (dispatch, state) => {
    dispatch(getCompanies());

    try {
      const companies = await companyService.getAll();
      dispatch(getCompaniesWithSuccess(companies));
    } catch (err) {
      dispatch(getCompaniesWithError(err));
    }
  };
}

export function fetchCompanyById(companyId) {
  return async (dispatch) => {
    dispatch(getCompany());

    try {
      const company = await companyService.getById(companyId);

      dispatch({
        type: SET_COMPANY,
        payload: { ...company },
      });
    } catch (err) {
      if (err && err.response && err.response.status === 404) {
        err.message = "Empresa não encontrada";
      }

      dispatch({ type: GET_COMPANY_ERROR, payload: err.message });
    }
  };
}

export function setCompany(company, cb) {
  return async (dispatch) => {
    dispatch({ type: SET_COMPANY, payload: company });

    if (cb) {
      cb(company);
    }
  };
}

export function deleteCompany(company, cb) {
  return async (dispatch) => {
    dispatch({ type: DELETE_COMPANY });

    const { companyId, logoUrl } = company;

    try {
      const success = await companyService.deleteById(companyId);

      if (success) {
        dispatch({ type: DELETE_COMPANY_SUCCESS, payload: companyId });
        await companyService.deleteLogo(logoUrl);
        dispatch({ type: DELETE_COMPANY_LOGO_SUCCESS });

        if (cb) {
          cb();
        }
      } else {
        throw new Error("Não foi possível deletar a empresa");
      }
    } catch (err) {
      dispatch({ type: DELETE_COMPANY_ERROR, payload: err });
    }
  };
}

export function uploadCompanyLogo(company, file) {
  return async function (dispatch) {
    dispatch({ type: UPLOAD_COMPANY_LOGO });

    try {
      const logoUrl = await companyService.uploadLogo(file);

      company.logoUrl = logoUrl;

      dispatch(updateCompanyLogo(company.companyId, logoUrl));
      dispatch({ type: UPLOAD_COMPANY_LOGO_SUCCESS, payload: company });
    } catch (err) {
      dispatch({ type: UPLOAD_COMPANY_LOGO_ERROR, payload: err });
    }
  };
}

export function uploadCandidatePhoto(company, employee, file) {
  return async function (dispatch) {
    dispatch({ type: UPLOAD_EMPLOYEE_PHOTO });

    try {
      const photoUrl = await companyService.uploadPhoto(
        file,
        company.companyId
      );

      employee.photoUrl = photoUrl;

      dispatch(updateEmployee(company.companyId, employee)).then(() =>
        dispatch({ type: UPLOAD_EMPLOYEE_PHOTO_SUCCESS })
      );
    } catch (err) {
      dispatch({ type: UPLOAD_EMPLOYEE_PHOTO_ERROR, payload: err });
    }
  };
}

export function updateEmployee(companyId, employee) {
  return async function (dispatch, getState) {
    dispatch({ type: UPDATE_EMPLOYEE });

    const state = getState();

    const newAreas = operations.updateEmployee(state.company, employee);

    try {
      await companyService.updateEmployee(companyId, employee);

      dispatch({
        type: SAVE_AREA_SUCCESS,
        payload: { areas: newAreas, companyId: companyId },
      });
    } catch (err) {
      dispatch({ type: UPDATE_COMPANY_ERROR, payload: err });
    }
  };
}

export function updateCompanyLogo(companyId, logoUrl) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_COMPANY_LOGO_URL });

    try {
      await companyService.updateLogoUrl(companyId, logoUrl);

      dispatch({ type: UPDATE_COMPANY_LOGO_URL_SUCCESS, payload: logoUrl });
    } catch (err) {
      dispatch({ type: UPDATE_COMPANY_LOGO_URL_ERROR, payload: err });
    }
  };
}

export function createCompany({ requestBody, election }, cb) {
  return async function (dispatch) {
    dispatch({ type: CREATE_COMPANY });

    try {
      requestBody.election = {
        name: election.electionName,
        extraInfo: election.extraInfo,
        isElectionPerArea: election.isElectionPerArea,
        requestApplicationForm: election.requestApplicationForm,
      };

      const company = await companyService.create(requestBody);

      dispatch({ type: CREATE_COMPANY_SUCCESS, payload: company });
      if (cb) {
        cb(company);
      }
    } catch (err) {
      dispatch({ type: CREATE_COMPANY_ERROR, payload: err.message });
    }
  };
}

export function updateCompany(companyId, requestBody, file) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_COMPANY });

    try {
      await companyService.updateById(companyId, requestBody);

      dispatch({
        type: UPDATE_COMPANY_SUCCESS,
        payload: requestBody,
      });

      if (file) {
        dispatch(uploadCompanyLogo({ companyId, ...requestBody }, file));
      }
    } catch (err) {
      dispatch({ type: UPDATE_COMPANY_ERROR, payload: err });
    }
  };
}

export function deleteCompanyLogo(logoUrl) {
  return async function (dispatch) {
    dispatch({ type: DELETE_COMPANY_LOGO });

    try {
      await companyService.deleteLogo(logoUrl);
      dispatch({ type: DELETE_COMPANY_LOGO_SUCCESS });
    } catch (err) {
      dispatch({ type: DELETE_COMPANY_LOGO_ERROR, payload: err });
    }
  };
}

export function getCompanyLogo(company, logoUrl) {
  return async function (dispatch) {
    dispatch({
      type: GET_COMPANY_LOGO,
      payload: company,
    });
  };
}

export function removeMessages() {
  return async function (dispatch) {
    dispatch({ type: REMOVE_MESSAGES });
  };
}

export function saveElectionOptions(companyId, params) {
  return async function (dispatch) {
    dispatch({ type: SAVE_ELECTION_CONFIG });

    const requestBody = params;

    try {
      await companyService.updateElection(companyId, requestBody);

      dispatch({
        type: SAVE_ELECTION_CONFIG_SUCCESS,
        payload: requestBody,
      });
    } catch (err) {
      dispatch({ type: SAVE_ELECTION_CONFIG_ERROR, payload: err });
    }
  };
}

export function changeElectionStatus(companyId, electionStates) {
  return async function (dispatch) {
    dispatch({ type: SAVE_ELECTION });

    const requestBody = {
      status: electionStates,
    };

    try {
      await companyService.updateElection(companyId, requestBody);

      dispatch({
        type: SAVE_ELECTION_SUCCESS,
        payload: requestBody,
      });
    } catch (err) {
      dispatch({ type: SAVE_ELECTION_ERROR, payload: err });
    }
  };
}

export function saveElectionConfig(companyId, params) {
  return async function (dispatch) {
    dispatch({ type: SAVE_ELECTION_CONFIG });

    const requestBody = {
      extraInfo: params.electionExtraInfo,
      name: params.electionName,
      isElectionPerArea: params.isElectionPerArea,
      requestApplicationForm: params.requestApplicationForm,
    };

    try {
      await companyService.updateElection(companyId, requestBody);

      dispatch({
        type: SAVE_ELECTION_CONFIG_SUCCESS,
        payload: requestBody,
      });
    } catch (err) {
      dispatch({ type: SAVE_ELECTION_CONFIG_ERROR, payload: err });
    }
  };
}
export function saveElectionPeriod(companyId, params) {
  return async function (dispatch) {
    dispatch({ type: SAVE_ELECTION_PERIOD });

    const requestBody = {
      subscribePeriodDateStart: params.subscribePeriodDateStart,
      subscribePeriodDateEnd: params.subscribePeriodDateEnd,
      subscribePeriodTimeStart: params.subscribePeriodTimeStart,
      subscribePeriodTimeEnd: params.subscribePeriodTimeEnd,
      electionDateStart: params.electionDateStart,
      electionDateEnd: params.electionDateEnd,
      electionTimeStart: params.electionTimeStart,
      electionTimeEnd: params.electionTimeEnd,
    };

    try {
      await companyService.updateElection(companyId, requestBody);

      dispatch({
        type: SAVE_ELECTION_PERIOD_SUCCESS,
        payload: requestBody,
      });
    } catch (err) {
      dispatch({ type: SAVE_ELECTION_PERIOD_ERROR, payload: err });
    }
  };
}

export function saveAreas(company, areas) {
  return async function (dispatch) {
    dispatch({ type: SAVE_AREA });

    const requestBody = {
      areas: areas,
    };

    try {
      await companyService.updateElection(company.companyId, requestBody);

      dispatch({
        type: SAVE_AREA_SUCCESS,
        payload: {
          areas: areas,
          companyId: company.companyId,
        },
      });
    } catch (err) {
      dispatch({ type: SAVE_AREA_ERROR, payload: err });
    }
  };
}

export function sendEmails(emails) {
  return async function (dispatch) {
    try {
      await companyService.sendEmails(emails);
    } catch (ex) {
      dispatch({ type: "SEND_EMAIL" });
    }
  };
}

export function checkElectionStatus(companyId) {
  return async function (dispatch) {
    dispatch({ type: UPDATE_ELECTION_STATUS });

    try {
      const response = await companyService.updateElectionStatus(companyId);
      const election = response.body;
      dispatch({ type: UPDATE_ELECTION_STATUS_SUCCESS, payload: election });
    } catch (ex) {
      dispatch({ type: UPDATE_ELECTION_STATUS_ERROR });
    }
  };
}

export function setManagerAccess() {
  return async function (dispatch) {
    dispatch({ type: "SET_MANAGER_ACCESS", payload: true });
  };
}

export const IMPORT_EMPLOYEES = "IMPORT_EMPLOYEES";
export const IMPORT_EMPLOYEES_SUCCESS = "IMPORT_EMPLOYEES_SUCCESS";
export const IMPORT_EMPLOYEES_ERROR = "IMPORT_EMPLOYEES_ERROR";

export function setImportEmployees(data, companyId) {
  return async function (dispatch) {
    dispatch({ type: IMPORT_EMPLOYEES });

    try {
      const response = await companyService.importEmployees(data, companyId);

      if (response.statusCode !== 200) {
        throw new Error(response.body);
      }

      dispatch({
        type: IMPORT_EMPLOYEES_SUCCESS,
        payload: response.body,
      });
    } catch (ex) {
      dispatch({
        type: IMPORT_EMPLOYEES_ERROR,
        payload: ex.message,
      });
    }
  };
}

export const SET_CONFLICTS = "SET_CONFLICTS";

export function setConflicts(conflictsFound) {
  return async function (dispatch) {
    dispatch({ type: SET_CONFLICTS, payload: conflictsFound });
  };
}

export const RESET_EMPLOYEE_MESSAGES = "RESET_EMPLOYEE_MESSAGES";
export function resetEmployeeMessages() {
  return async function (dispatch) {
    dispatch({ type: RESET_EMPLOYEE_MESSAGES });
  };
}
