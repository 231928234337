import React from "react";

import * as companyActions from "../../../../store/actions/companyActions";
import * as uiActions from "../../../../store/actions/uiActions";

import * as yup from "yup";

import {
  Button,
  Box,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";

import { useFormik } from "formik";
import { connect } from "react-redux";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";
import DatePicker from "../../../UI/DatePicker/DatePicker";
import DateTimePicker from "../../../UI/DateTimePicker/DateTimePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "@media (max-width: 400px)": {
      width: "270px",
    },
  },
  title: {
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(2) + "px 0",
  },
}));

const validationSchema = yup.object({
  subscribePeriodDateStart: yup.string().required("Requerido"),
  subscribePeriodDateEnd: yup.string().required("Requerido"),
  subscribePeriodTimeStart: yup.string().required("Requerido"),
  subscribePeriodTimeEnd: yup.string().required("Requerido"),
  electionDateStart: yup.string().required("Requerido"),
  electionDateEnd: yup.string().required("Requerido"),
  electionTimeStart: yup.string().required("Requerido"),
  electionTimeEnd: yup.string().required("Requerido"),
});

const ElectionPeriodModal = ({
  company,
  dispatch,
  errorMessage,
  isLoading,
}) => {
  const classes = useStyles();
  const { election } = company;

  const formik = useFormik({
    initialValues: {
      subscribePeriodDateStart: election.subscribePeriodDateStart,
      subscribePeriodDateEnd: election.subscribePeriodDateEnd,
      subscribePeriodTimeStart: election.subscribePeriodTimeStart,
      subscribePeriodTimeEnd: election.subscribePeriodTimeEnd,
      electionDateStart: election.electionDateStart,
      electionDateEnd: election.electionDateEnd,
      electionTimeStart: election.electionTimeStart,
      electionTimeEnd: election.electionTimeEnd,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      dispatch(companyActions.saveElectionPeriod(company.companyId, data));
      dispatch(uiActions.closeMqvModal());
    },
  });

  return (
    <Box>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Box id="frm-electionConfig">
          <Box my={3}>
            <Typography variant="subtitle1" className={classes.title}>
              Periodo Inscrição
            </Typography>
            <Box className={classes.formControl} display="flex">
              <DatePicker
                fullWidth
                fieldId="subscribePeriodDateStart"
                label="Data Início"
                formik={formik}
              ></DatePicker>

              <DateTimePicker
                fullWidth
                fieldId="subscribePeriodTimeStart"
                label="Horário Início"
                formik={formik}
              ></DateTimePicker>
            </Box>
            <Box my={1}>
              <Divider />
            </Box>
            <Box className={classes.formControl} display="flex">
              <DatePicker
                fullWidth
                fieldId="subscribePeriodDateEnd"
                label="Data Encerramento"
                formik={formik}
              ></DatePicker>
              <DateTimePicker
                fullWidth
                fieldId="subscribePeriodTimeEnd"
                label="Horário Encerramento"
                formik={formik}
              ></DateTimePicker>
            </Box>
          </Box>
          <Box my={3}>
            <Typography variant="subtitle1" className={classes.title}>
              Periodo Eleição
            </Typography>
            <Box className={classes.formControl} display="flex">
              <DatePicker
                fullWidth
                fieldId="electionDateStart"
                label="Data Início"
                formik={formik}
              ></DatePicker>
              <DateTimePicker
                fullWidth
                fieldId="electionTimeStart"
                label="Horário Início"
                formik={formik}
              ></DateTimePicker>
            </Box>
            <Box my={1}>
              <Divider />
            </Box>
            <Box className={classes.formControl} display="flex">
              <DatePicker
                fullWidth
                fieldId="electionDateEnd"
                label="Data Encerramento"
                formik={formik}
              ></DatePicker>
              <DateTimePicker
                fullWidth
                fieldId="electionTimeEnd"
                label="Horário Encerramento"
                formik={formik}
                value="10:20"
              ></DateTimePicker>
            </Box>
          </Box>
        </Box>

        {errorMessage ? (
          <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
        ) : null}

        <Box my={1}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

const mapsStateToProps = (state) => ({
  company: state.company.company,
  errorMessage: state.company.errorMessage,
  isLoading: state.company.isLoading,
});

export default connect(mapsStateToProps)(ElectionPeriodModal);
