import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import NewPassword from "./pages/new-password";
import ForgotPassword from "./pages/forgot-password";
import Company from "./pages/company";
import Election from "./pages/election";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>
      
      <Route exact path="/company/:id">
        <Company />
      </Route>

      <Route exact path="/new-password">
        <NewPassword />
      </Route>

      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>

      <Route exact path="/election/:code">
        <Election />
      </Route>
    </Switch>
  );
}
