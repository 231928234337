import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import SignInBox from "./SignInBox/SignInBox";
import { withRouter } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

import { Auth } from "aws-amplify";

const Login = (props) => {
  const { userHasAuthenticated } = useAppContext();
  const [error, setError] = useState(null);
  const history = useHistory();

  const onSubmitHandler = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);

      userHasAuthenticated(user);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return history.push("/new-password")
      }

      history.push("/");
    } catch (ex) {
      setError("Usuário ou Senha incorretos!");
    }
  };

  return <SignInBox onSubmitHandler={onSubmitHandler} errorMessage={error} />;
};

export default withRouter(Login);
