import axios from "axios";

const APIKey = "50ad4a90-fd5e-11ec-b463-1717be8c9ff1";

export async function getGeolocation() {
  const geolocation = await axios.get(
    "https://geolocation-db.com/json/" + APIKey
  );

  return geolocation;
}
