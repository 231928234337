const EMPLOYEE_COLUMNS = [
  {
    id: "registration",
    label: "Matrícula",
    minWidth: 80,
    align: "left",
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 100,
    align: "left",
  },
  {
    id: "areaName",
    label: "Área",
    minWidth: 100,
    align: "left",
  },
];

export default EMPLOYEE_COLUMNS;
