const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
const AWS_APIGATEWAY_URL = process.env.REACT_APP_AWS_APIGATEWAY_URL;
const AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;
const AWS_COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID;
const AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;

const config = {
  s3: {
    REGION: AWS_REGION,
    BUCKET: AWS_S3_BUCKET,
  },
  apiGateway: {
    REGION: AWS_REGION,
    URL: AWS_APIGATEWAY_URL,
  },
  cognito: {
    REGION: AWS_REGION,
    USER_POOL_ID: AWS_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: AWS_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: AWS_COGNITO_IDENTITY_POOL_ID,
  },
};

export default config;
