import React, { useEffect } from "react";
import { Box, CircularProgress, Divider } from "@material-ui/core";

import ErrorMessage from "../UI/ErrorMessage/ErrorMessage";
import CompanyForm from "./CompanyForm/CompanyForm";

import Election from "./Election/Election";
import { useRouteMatch } from "react-router";

import { makeStyles } from "@material-ui/core";

import {
  fetchCompanyById,
  checkElectionStatus,
} from "../../store/actions/companyActions";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  companyForm: {
    width: "100%",
    maxWidth: "270px",
    background: theme.palette.grey[200],
    padding: theme.spacing(3),
    height: "100%",
  },
}));

const CompanyDashboard = ({ hasErrors, company, dispatch }) => {
  const classes = useStyles();

  const match = useRouteMatch();
  const companyId = match.params.id;

  useEffect(() => {
    if (!company) {
      dispatch(fetchCompanyById(companyId));
    }
  }, [companyId, dispatch, company]);

  useEffect(() => {
    dispatch(checkElectionStatus(companyId));
  }, [dispatch, companyId]);

  let rendered = (
    <Box py={3} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );

  if (company) {
    rendered = (
      <React.Fragment>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box>
                {company.logoUrl ? (
                  <img
                    style={{ width: "70px", marginRight: "8px" }}
                    src={company.logoUrl}
                    alt="Logo da empresa"
                  />
                ) : null}
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexGrow={1}>
            <Box display="flex">
              <Box className={classes.companyForm}>
                <CompanyForm
                  formTitle="Informações da Empresa"
                  formData={company}
                ></CompanyForm>

                <Box my={3}>
                  <Divider />
                </Box>
              </Box>
            </Box>
            <Box px={2} flexGrow={1}>
              <Box p={2}>
                <Election company={company}></Election>
              </Box>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  if (hasErrors) {
    rendered = (
      <Box py={3}>
        <ErrorMessage>{hasErrors}</ErrorMessage>
      </Box>
    );
  }

  return rendered;
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    hasErrors: state.company.hasErrors,
  };
};

export default connect(mapStateToProps)(CompanyDashboard);
