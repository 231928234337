import React from "react";

import { Box, makeStyles, Typography, Button } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[200],
    borderRadius: 8,
    padding: theme.spacing(2),
    height: 270,
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "column",
  },
  Logo: {
    width: "100%",
    height: "80px",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  Infos: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(10),
  },
}));

const CompanyCard = (props) => {
  const classes = useStyle();
  const { logoUrl } = props;

  return (
    <Box p={3} width={183} className={classes.root}>
      {logoUrl ? (
        <div
          className={classes.Logo}
          style={{
            backgroundImage: `url('${logoUrl}')`,
          }}
        ></div>
      ) : null}
      <Box my={3}>
        <Typography variant="body1" align="center">
          {props.surname}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={props.buttonClicked}
        >
          Administrar
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyCard;
