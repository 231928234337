import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import EmployeesForm from "./EmployeesForm";
import EmployeesList from "./EmployeesList";

import * as companyActions from "../../../../store/actions/companyActions";
import * as companyOperations from "../../../../store/operations/companyOperations";
import * as uiActions from "../../../../store/actions/uiActions";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";
import SuccessMessage from "../../../UI/SuccessMessage/SuccessMessage";
import UploadButton from "../../../UI/UploadButton";
import { handleFileUpload } from "../../../../libs/xls";

const ORIGINS = {
  IMPORTED: "IMPORTED",
  REGISTERED: "REGISTERED",
};

const ElectionEmployeesModal = ({
  company,
  mappedAreas,
  dispatch,
  isLoading,
  errorMessage,
  conflicts,
  importationInfo,
  managerAccess,
}) => {
  const { election } = company;

  const [itemToEdit, setItemToEdit] = useState(null);
  const [areas, setAreas] = useState([]);
  const [section, setSection] = useState("list");

  const setConflicts = (foundConflicts) => {
    resetMessages();
    dispatch(companyActions.setConflicts(foundConflicts));
  };

  const resetMessages = useCallback(() => {
    dispatch(companyActions.resetEmployeeMessages());
  }, [dispatch]);

  const checkEmployeeConflicts = (areas, employeeToCheck) => {
    const conflictsFound = {};
    for (let area of areas) {
      for (let employee of area.employees) {
        if (employee.subscribeNumber !== employeeToCheck.subscribeNumber) {
          const propertyToCheck = [
            { attribute: "registration", description: "Matrícula" },
            { attribute: "username", description: "Usuário" },
          ];

          for (let property of propertyToCheck) {
            if (
              employee[property.attribute] ===
              employeeToCheck[property.attribute]
            ) {
              conflictsFound[employeeToCheck.subscribeNumber] =
                conflictsFound[employeeToCheck.subscribeNumber] || [];
              conflictsFound[employeeToCheck.subscribeNumber].push({
                property: property.description,
                employee: {
                  name: employee.name,
                  registration: employee.registration,
                  areaName: area.areaName,
                  areaId: area.areaId,
                  origin: ORIGINS.REGISTERED,
                },
                checkedEmployee: {
                  name: employeeToCheck.name,
                  areaId: employeeToCheck.areaId,
                },
              });
            }
          }
        }
      }
    }

    const mappedConflicts = Object.keys(conflictsFound).map((key) => {
      return {
        subscribeNumber: key,
        items: conflictsFound[key],
      };
    });

    return mappedConflicts;
  };

  const onNewItemHandler = (newEmployee) => {
    const conflictsFound = checkEmployeeConflicts(areas, newEmployee);
    let newAreas = areas;

    if (conflictsFound.length > 0) {
      setConflicts(conflictsFound);
    } else {
      setConflicts(null);
      newAreas = areas.map((area) => {
        if (area.areaId === newEmployee.areaId) {
          area.employees = [...area.employees, newEmployee];
        }

        return area;
      });

      resetMessages();
    }

    setSection("list");
    setItems(newAreas);
    setItemToEdit(null);
  };

  const onUpdatedItemHandler = (updatedEmployee) => {
    const employeeArea = mappedAreas[updatedEmployee.areaId];
    const isChangedArea = updatedEmployee.areaId !== updatedEmployee.lastAreaId;
    const conflictsFound = checkEmployeeConflicts(areas, updatedEmployee);

    let updatedAreas = areas;

    if (conflictsFound.length > 0) {
      setConflicts(conflictsFound);
    } else {
      setConflicts(null);
      resetMessages();
      if (isChangedArea) {
        const lastEmployeeArea = mappedAreas[updatedEmployee.lastAreaId];
        lastEmployeeArea.employees = lastEmployeeArea.employees.filter(
          (employee) =>
            employee.subscribeNumber !== updatedEmployee.subscribeNumber
        );

        updatedAreas = companyOperations.updateAreas(
          mappedAreas,
          areas,
          updatedEmployee.lastAreaId
        );

        updatedEmployee.lastAreaId = updatedEmployee.areaId;

        employeeArea.employees.push(updatedEmployee);
      } else {
        employeeArea.employees = employeeArea.employees.map((employee) => {
          if (employee.subscribeNumber === updatedEmployee.subscribeNumber) {
            return {
              ...employee,
              ...updatedEmployee,
            };
          }

          return employee;
        });
      }

      updatedAreas = companyOperations.updateAreas(
        mappedAreas,
        areas,
        updatedEmployee.areaId
      );
    }

    setSection("list");
    setItems(updatedAreas);
    setItemToEdit(null);
  };

  const onItemEditRequestHandler = (editEmployee) => {
    setItemToEdit(editEmployee);
    setSection("itemForm");
  };

  const onItemNewRequestHandler = (editEmployee) => {
    setItemToEdit(null);
    setSection("itemForm");
  };

  const onItemRemovedHandler = (employedToRemove) => {
    const newAreas = areas.map((area) => {
      if (area.areaId === employedToRemove.areaId) {
        const updatedEmployees = area.employees.filter(
          (employee) =>
            employee.subscribeNumber !== employedToRemove.subscribeNumber
        );

        area.employees = updatedEmployees;
      }

      return area;
    });

    setSection("list");
    setItems(newAreas);
    setItemToEdit(null);
    resetMessages();
  };

  const onSaveItemsHandler = useCallback(
    (newAreas) => {
      resetMessages();

      dispatch(companyActions.saveAreas(company, newAreas || areas));
      dispatch(uiActions.closeMqvModal());
    },
    [dispatch, company, areas, resetMessages]
  );

  /**
   *
   * WORKNG IN PROGRESS ...
   */
  const onUploadedHandler = (data) => {
    dispatch(companyActions.setImportEmployees(data.list, company.companyId));
  };

  const setItems = useCallback((newAreas) => {
    setAreas(newAreas);
    //onSaveItemsHandler(newAreas);
  }, []);

  useEffect(() => {
    setItems(company.election.areas);
  }, [company.election.areas, setItems, resetMessages]);

  useEffect(() => {
    console.log(errorMessage);
  }, [errorMessage]);

  return (
    <Box my={3}>
      {isLoading ? (
        <Box
          py={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          key="company-loading"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mb={1}>
          {section === "itemForm" ? (
            <EmployeesForm
              managerAccess={managerAccess}
              areaList={areas}
              uniqueArea={!election.isElectionPerArea}
              employee={itemToEdit}
              onNewEmployee={(newEmployee) => onNewItemHandler(newEmployee)}
              onUpdatedEmployee={(updatedEmployee) =>
                onUpdatedItemHandler(updatedEmployee)
              }
              onCancel={() => setSection("list")}
            />
          ) : null}

          {section === "list" ? (
            <>
              {!managerAccess ? (
                <Box my={2} display="flex" justifyContent="flex-end">
                  <Box pl={1}>
                    <Button
                      variant="contained"
                      onClick={() => onItemNewRequestHandler()}
                      disabled={isLoading}
                    >
                      Novo Funcionario
                    </Button>
                  </Box>
                  <Box pl={1}>
                    <UploadButton
                      onChange={(e) =>
                        handleFileUpload(e, (data) => onUploadedHandler(data))
                      }
                    >
                      Importar Funcionarios (XLSX)
                    </UploadButton>
                  </Box>
                </Box>
              ) : null}

              {errorMessage ? (
                <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
              ) : null}

              {importationInfo ? (
                <SuccessMessage severity="error">
                  {importationInfo}
                </SuccessMessage>
              ) : null}

              {conflicts && conflicts.length > 0 ? (
                <Box my={1}>
                  <ErrorMessage severity="error">
                    {conflicts.length > 1 ? (
                      <Box>
                        <Typography variant="body1">
                          Não foi possível importar os funcionários abaixo ...
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography variant="body1">
                          Não foi possível adicionar ou atualizar o funcionário{" "}
                          {conflicts[0].items[0].checkedEmployee.name}, por que
                          os campos ...
                        </Typography>
                      </Box>
                    )}
                    <Box my={1}>
                      <Divider />
                    </Box>
                    {conflicts.map((conflict, key) => {
                      return (
                        <Box key={key}>
                          {conflicts.length > 1 ? (
                            <Box>
                              <Typography variant="body1">
                                Conflitos com o Funcionario{" "}
                                {conflict.importedEmployee.name}
                              </Typography>
                            </Box>
                          ) : null}
                          {conflict.items.map((item, itemKey) => {
                            return (
                              <Box key={itemKey}>
                                <Box>
                                  <Typography variant="caption" key={key}>
                                    O campo "{item.property}" é igual ao do
                                    funcionário
                                    {item.employee.origin === ORIGINS.REGISTERED
                                      ? " " +
                                        item.employee.name +
                                        " já registrado na empresa " +
                                        " que é da Area " +
                                        item.employee.areaName
                                      : " da importação " +
                                        item.employee.name +
                                        " que é da areaId " +
                                        item.employee.areaId.substring(
                                          0,
                                          8
                                        )}{" "}
                                    ...
                                  </Typography>
                                </Box>
                                <Box my={1}>
                                  <Divider />
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </ErrorMessage>
                </Box>
              ) : null}

              <EmployeesList
                managerAccess={managerAccess}
                areas={areas}
                onEmployeeEditRequest={(editEmployee) =>
                  onItemEditRequestHandler(editEmployee)
                }
                onEmployeeRemoved={(employedToRemove) =>
                  onItemRemovedHandler(employedToRemove)
                }
              />

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mt={1}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => onSaveItemsHandler()}
                  disabled={isLoading}
                >
                  Salvar
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    mappedAreas: state.company.areas,
    isLoading: state.company.isLoading,
    errorMessage: state.company.errorMessage,
    conflicts: state.company.conflicts,
    importationInfo: state.company.importationInfo,
    managerAccess: state.company.managerAccess,
  };
};

export default connect(mapStateToProps)(ElectionEmployeesModal);
