import { Toolbar, makeStyles, colors } from "@material-ui/core";

import Drawer from "@material-ui/core/Drawer";

const drawerWidth = 64;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: colors.grey[800],
  },
  drawerContainer: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));

const SideDrawer = (props) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>{props.children}</div>
    </Drawer>
  );
};

export default SideDrawer;
