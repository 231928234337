export const SET_SOCKET = "SET_SOCKET";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const SET_IS_CONNECTED = "SET_IS_CONNECTED";

export function setIsConnected(connected) {
  return async function (dispatch) {
    dispatch({ type: SET_IS_CONNECTED, payload: connected})
  };
}

export function setSocket(socket) {
  return async function (dispatch) {
    dispatch({ type: SET_SOCKET, payload: socket });
  };
}

export function newMessage(message) {
  return async function (dispatch) {
    dispatch({ type: NEW_MESSAGE, payload: JSON.parse(message) });
  };
}
