import {
  Box,
  Button,
  TextField,
  makeStyles,
  colors,
  Typography,
} from "@material-ui/core";
import ErrorMessage from "../UI/ErrorMessage/ErrorMessage";
import React, { useState } from "react";
import SuccessMessage from "components/UI/SuccessMessage/SuccessMessage";

const styles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  loginBox: {
    background: colors.grey[50],
  },
}));

export default function NewPasswordBox(props) {
  const classes = styles();
  const [password, setPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [verificationCode, setCode] = useState(null);
  const [userName, setUsername] = useState(null);
  const [section, setSection] = useState("password");

  const submitFormHandler = () => {
    if (props.resetFlow && section !== "code") {
      //send e-mail here
      props.onSentCode(userName);
      setSection("code");
    } else {
      props.onSubmitHandler(oldPassword, password, userName, verificationCode);
    }
  };

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        boxShadow={3}
        p={3}
        borderRadius={8}
        maxWidth="350px"
        className={classes.loginBox}
      >
        <Box textAlign="center" my={7} >
          <img src="/logo-mqv-pro.png" alt="logo" />
        </Box>
        {section === "password" ? (
          <>
            <Typography variant="h5">
              Você precisa colocar uma nova senha para prosseguir
            </Typography>

            {props.resetFlow ? (
              <Box my={5}>
                <TextField
                  id="username"
                  type="text"
                  label="Nome de usuário"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(event) => setUsername(event.target.value)}
                />
              </Box>
            ) : (
              <Box my={5}>
                <TextField
                  id="old_password"
                  type="password"
                  label="Senha atual"
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(event) => setOldPassword(event.target.value)}
                />
              </Box>
            )}

            <Box my={5}>
              <TextField
                id="password"
                type="password"
                label="Nova senha"
                variant="outlined"
                autoComplete="off"
                fullWidth
                margin="normal"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5">
              Utilize o código de verificação que foi enviado para o seu e-mail,
              no campo abaixo:
            </Typography>
            <Box my={5}>
              <TextField
                id="code"
                type="text"
                label="Código de Verificação"
                variant="outlined"
                fullWidth
                autoComplete="off"
                margin="normal"
                onChange={(event) => setCode(event.target.value)}
              />
            </Box>
          </>
        )}

        {props.errorMessage ? (
          <ErrorMessage severity="error">{props.errorMessage}</ErrorMessage>
        ) : null}

        {props.successMessage ? (
          <SuccessMessage severity="error">
            {props.successMessage}
          </SuccessMessage>
        ) : null}

        <Box my={5}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => submitFormHandler()}
          >
            {props.resetFlow && section !== "code" ? "Prosseguir" : "Entrar"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
