import React from "react";
import TextInput from "../TextInput/TextInput";

export default function DateTimePicker({
  label,
  formik,
  fieldId,
  handleChange,
}) {
  return (
    <TextInput
      label={label}
      fieldId={fieldId}
      formik={formik}
      type="time"
    />
  );
}
