import React, { useEffect, useState } from "react";

import * as uiActions from "../../../../store/actions/uiActions";
import * as companyActions from "../../../../store/actions/companyActions";

import * as yup from "yup";

import {
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";

import { useFormik } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";
import TextInput from "../../../UI/TextInput/TextInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "@media (max-width: 400px)": {
      width: "270px",
    },
  },
  title: {
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(2) + "px 0",
  },
}));

const validationSchema = yup.object({
  electionName: yup
    .string("Insira o nome da eleição")
    .required("Nome da eleição é requerido!"),
});

const ElectionConfigModal = ({
  company,
  dispatch,
  errorMessage,
  isLoading,
}) => {
  const [isElectionPerArea, setIsElectionPerArea] = useState(false);
  const [requestApplicationForm, setRequestApplicationForm] = useState(false);

  const classes = useStyles();
  const { election } = company;

  useEffect(() => {
    setIsElectionPerArea(election.isElectionPerArea);
    setRequestApplicationForm(election.requestApplicationForm);
  }, [election.isElectionPerArea, election.requestApplicationForm]);

  const formik = useFormik({
    initialValues: {
      electionName: election.name,
      electionExtraInfo: election.extraInfo,
      isElectionPerArea: election.isElectionPerArea,
      requestApplicationForm: election.requestApplicationForm,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      dispatch(
        companyActions.saveElectionConfig(company.companyId, {
          ...data,
          isElectionPerArea,
          requestApplicationForm,
        })
      );

      dispatch(uiActions.closeMqvModal());
    },
  });

  return (
    <Box>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Box id="frm-electionConfig">
          <Box my={3}>
            <Box className={classes.formControl}>
              <TextInput
                fieldId="electionName"
                formik={formik}
                label="Nome da Eleição"
              />
            </Box>
            <Box>
              <TextInput
                fieldId="electionExtraInfo"
                formik={formik}
                label="Descrição Cedula Adicional (Opcional)"
              />
            </Box>
            <Box className={classes.formControl} style={{ paddingLeft: 10 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isElectionPerArea}
                      onChange={(event) => {
                        setIsElectionPerArea(event.target.checked);
                      }}
                      disabled={(company.companyId ? true : false)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Eleição por area"
                ></FormControlLabel>
              </FormGroup>
            </Box>
          </Box>
        </Box>

        {errorMessage ? (
          <ErrorMessage severity="error">{errorMessage}</ErrorMessage>
        ) : null}

        <Box my={1}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

const mapsStateToProps = (state) => ({
  company: state.company.company,
  errorMessage: state.company.errorMessage,
  isLoading: state.company.isLoading,
});

export default connect(mapsStateToProps)(ElectionConfigModal);
