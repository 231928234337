import React from "react";

import { Box } from "@material-ui/core";
import Filter from "../../../../../UI/Filter";

const EmployeesFilter = ({ onResult, employeesList = [], areasList = [] }) => {
  const fields = [
    {
      type: "text",
      label: "Nome",
      labelId: "name",
    },
    {
      type: "text",
      label: "Matricula ",
      labelId: "registration",
    },
  ];

  if (areasList.length > 1) {
    fields.push({
      type: "select",
      label: "Area",
      labelId: "areaId",
      styles: {
        minWidth: "150px"
      },
      options: areasList.map((area) => {
        return {
          value: area.areaId,
          text: area.areaName,
        };
      }),
    });
  }

  return (
    <Box my={2}>
      <Filter list={employeesList} fields={fields} doFilter={onResult} />
    </Box>
  );
};

export default EmployeesFilter;
