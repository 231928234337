import * as actions from "../actions/uiActions";

const initialState = {
  mqvModalOpen: false,
  mqvModalChildren: null,
  mqvModalTitle: null,
  mqvModalSize: 400,
  candidatePreviewModalOpen: false
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_MQV_MODAL:
      return {
        ...state,
        mqvModalOpen: true,
        mqvModalChildren: action.payload,
      };
    case actions.SET_MQV_MODAL_TITLE:
      return {
        ...state,
        mqvModalTitle: action.payload,
      };
    case actions.CLOSE_MQV_MODAL:
      return {
        ...state,
        mqvModalOpen: false,
        mqvModalChildren: null,
        mqvModalTitle: null,
      };
    case actions.SET_MQV_MODAL_SIZE:
      return {
        ...state,
        mqvModalSize: action.payload 
      };
    case actions.SHOW_CANDIDATEPREVIEW_MODAL:
      return {
        ...state,
        candidatePreviewModalOpen: action.payload 
      };
    default:
      return state;
  }
}
